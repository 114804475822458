.single-publication-item {
  margin-bottom: 60px;
}
.publication-content-wrapper{
    background: #FFFFFF;
    padding: 20px;
}
.publication-meta {
  margin-bottom: 10px;
  margin-top: -4px;
}

.publication-meta li {
  font-size: 16px;
  line-height: 25px;
  margin-right: 50px;
  margin-top: 7px;
}

.publication-meta li i {
  margin-right: 5px;
  color: #191B1E;
}

.publication-meta li a {
  color: #787B84;
}
article .publication-title {
  margin-top: 0;
  margin-bottom: 14px;
  font-size: 28px;
  word-break: break-word;
}
.publication-excerpt{
    font-size: 16px;
    color: #191B1E;
}
article .publication-title a {
    color: currentColor;
}

.publication-thumbnail-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 20px 20px 0 0;
    -webkit-border-radius: 20px 20px 0 0;
    -moz-border-radius: 20px 20px 0 0;
    -ms-border-radius: 20px 20px 0 0;
    -o-border-radius: 20px 20px 0 0;
  }
  
  .publication-thumbnail-wrapper img {
    -webkit-transition: 0.5s cubic-bezier(0.25, 1, 0.5, 1);
    -o-transition: 0.5s cubic-bezier(0.25, 1, 0.5, 1);
    transition: 0.5s cubic-bezier(0.25, 1, 0.5, 1);
    width: 100%;
  }
  .single-publication-item:hover .publication-thumbnail-wrapper img {
    -webkit-transform: scale(1.08);
    -ms-transform: scale(1.08);
    transform: scale(1.08);
  }

  .publication-read-more {
    margin-top: 35px;
  }
  
  .publication-read-more .xb-btn {
    padding: 18px 40px;
  }
  .publication-details .publication-thumb {
    margin-bottom: 30px;
}
.publication-details .publication-thumb img{
    width: 100%;
    border-radius: 20px;
  }
  
  .publication-details h2 {
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 30px;
  }
.event-details h3{font-size: 26px;}
.event-details ul{margin-top: 10px;}
.event-details ul li{list-style: none;}
.event-details ul li{margin-bottom: 10px;}
@media (max-width: 767px) {
  article .publication-title {
    font-size: 24px;
  }
}