@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&amp;display=swap");

:root {
  --font-body: "Inter", sans-serif;
  --font-heading: 'Recoleta';
  --color-primary: #0B3948;
  --color-heading: #0b3948;
  --color-default: #5d6a83;
  --color-default-two: #4c6a6d;
  --color-yellow: #ffd470;
  --xb-track-color: #06083c;
  --color-dark: #0F172A;
  --color-white: #fff;
  --color-black: #000;
  --color-gray: #F8F8F8;
  --color-border: #EFF5F8;
  --color-body: #F7F5F2;
  --easing: cubic-bezier(0.67, 0.04, 0.3, 0.91);
}

/* reset css start */
:root {
  scroll-behavior: auto;
}

html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  font-weight: 400;
  position: relative;
  font-family: var(--font-body);
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: var(--color-default);
  background-color: var(--color-body);
  scrollbar-color: var(--color-primary) var(--xb-track-color);
  scrollbar-width: thin;
}

body::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-track {
  background: var(--xb-track-color);
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 10px;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  margin: 0px;
  padding: 0px;
}

button {
  cursor: pointer;
}

*:focus {
  outline: none;
}

button {
  border: none;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

button:focus {
  outline: none;
}

a {
  text-decoration: none;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

a:hover {
  color: inherit;
}

select {
  height: 55px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../img/icon/select-arrow.html);
  background-position: calc(100% - 10px) 50%;
  background-repeat: no-repeat;
  padding-right: 20px;
  background-color: transparent;
  border: 0;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
form select,
textarea {
  width: 100%;
  height: 75px;
  border-radius: 0;
  background-color: #F3F4F5;
  padding: 24px 20px;
  border: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  color: var(--color-black);
  font-weight: 500;
}

input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
form select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #888686;
  opacity: 1;
}

input[type="text"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
form select::-moz-placeholder,
textarea::-moz-placeholder {
  color: #888686;
  opacity: 1;
}

input[type="text"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
form select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #888686;
  opacity: 1;
}

input[type="text"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
input[type="tel"]:-moz-placeholder,
form select:-moz-placeholder,
textarea:-moz-placeholder {
  color: #888686;
  opacity: 1;
}

textarea {
  height: 100px;
}

button {
  border: 0;
}

table {
  width: 100%;
}

p,
li,
span {
  margin-bottom: 0;
}

/* reset css end */
/* global css start */
.nice-select {
  background-color: transparent;
  height: 60px;
  line-height: 59px;
  min-height: 60px;
  padding: 0 20px;
  width: 100%;
  padding: 0 46px;
  margin-bottom: 20px;
  background-color: #F3F4F5;
  border: 0;
  font-size: 16px;
}

.nice-select::after {
  border-bottom: 1.9px solid #4C6A6D;
  border-right: 1.9px solid #4C6A6D;
  height: 6px;
  right: 20px;
  width: 6px;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #F3F4F5;
}

.nice-select .list {
  width: 100%;
}

.nice-select .list li {
  margin-right: 0 !important;
}

.nice-select .list .option {
  color: var(--color-dark);
  font-weight: 500;
}

.nice-select .list .option.selected {
  font-weight: 600;
}

.nice-select .list .option.selected,
.nice-select .list .option:hover {
  border: none !important;
}

/* global css end */
.body_wrap {
  position: relative;
}

.bg_img {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.footer-bg {
  background-color: #04060A;
}

.black-bg {
  background: var(--color-black);
}

.gray-bg {
  background-color: var(--color-gray);
}

.gray-bg-2 {
  background-color: var(--color-gray-2);
}

.dark-bg {
  background-color: var(--color-dark);
}

.white {
  color: var(--color-white);
}

.pos-rel {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.f-right {
  float: right;
}

.radius-20 {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.border-effect a,
.border-effect-2 a {
  display: inline !important;
  width: 100%;
  background-repeat: no-repeat;
  background-position-y: -2px;
  background-image: linear-gradient(transparent calc(100% - 2px), currentColor 1px);
  -webkit-transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  background-size: 0 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.border-effect a:hover,
.border-effect-2 a:hover {
  background-size: 100% 100%;
  color: inherit;
}

.border-effect-2 a {
  background-image: linear-gradient(transparent calc(100% - 1px), currentColor 1px);
}

.btn-video {
  width: 73px;
  height: 73px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: #1140C0;
  position: relative;
  background-color: var(--color-white);
}

@media (max-width: 767px) {
  .btn-video {
    width: 60px;
    height: 60px;
    font-size: 18px;
  }
}

.btn-video:hover {
  color: var(--color-primary);
}

.btn-video::before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  z-index: -2;
}

.btn-video-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.border_effect a {
  display: inline !important;
  width: 100%;
  background-repeat: no-repeat;
  background-position-y: -2px;
  background-image: linear-gradient(transparent calc(100% - 2px), currentColor 1px);
  transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  background-size: 0 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.border_effect a:hover {
  background-size: 100% 100%;
  color: inherit;
}

@media (max-width: 991px) {
  .tx-col-md-6 {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .tx-col-md-6 {
    width: 100%;
  }
}

.xb-close {
  background: rgba(0, 0, 0, 0.04);
  border: 9px solid transparent;
  color: #777;
  width: 36px;
  height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.xb-close::before,
.xb-close::after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  transform-origin: 50% 50%;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -khtml-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -ms-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: #1b1b1b;
}

.xb-close::before {
  -webkit-transform: rotate(45deg);
  -khtml-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.xb-close::after {
  -webkit-transform: rotate(-45deg);
  -khtml-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.xb-close:hover::before,
.xb-close:hover::after {
  -webkit-transform: rotate(0);
  -khtml-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
}

/* order & unorder list reset - start */
.ul_li,
.ul_li_right,
.ul_li_center,
.ul_li_between {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.ul_li>li,
.ul_li_right>li,
.ul_li_center>li,
.ul_li_between>li {
  list-style: none;
  display: inline-block;
}

.ul_li {
  justify-content: flex-start;
}

.ul_li_center {
  justify-content: center;
}

.ul_li_right {
  justify-content: flex-end;
}

.ul_li_between {
  justify-content: space-between;
}

.ul_li_block {
  margin: 0px;
  padding: 0px;
  display: block;
}

.ul_li_block>li {
  display: block;
  list-style: none;
}

.flex-1 {
  flex: 1;
}

.color-black {
  color: var(--color-black);
}

.pagination_wrap ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -5px;
  list-style: none;
}

.pagination_wrap ul li {
  padding: 5px;
}

.pagination_wrap ul li a {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-primary);
  border: 1px solid #EEE5E5;
  background-color: var(--color-white);
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 1;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  overflow: hidden;
}

@media (max-width: 767px) {
  .pagination_wrap ul li a {
    height: 40px;
    width: 40px;
    font-size: 15px;
  }
}

.pagination_wrap ul li a.current_page,
.pagination_wrap ul li a:hover {
  background-color: var(--color-yellow);
  border-color: var(--color-yellow);
}

.mr-none-60 {
  margin-right: -60px;
}

.ml-none-60 {
  margin-left: -60px;
}

.pb-8 {
  padding-bottom: 8px;
}

.bg-leniar {
  background: linear-gradient(180deg, #f7f5f2 15.68%, #fff 100%);
}

.xb-odm {
  overflow: hidden;
}

.xb-odm .xbo.xbo-auto-theme .xbo-digit .xbo-digit-inner,
.xb-odm .xbo.xbo-theme-default .xbo-digit .xbo-digit-inner {
  overflow: initial;
  overscroll-behavior-y: hidden;
}

/*--
    - Margin & Padding
-----------------------------------------*/
/*-- Margin Top --*/
.mt-none-5 {
  margin-top: -5px;
}

.mt-none-10 {
  margin-top: -10px;
}

.mt-none-15 {
  margin-top: -15px;
}

.mt-none-20 {
  margin-top: -20px;
}

.mt-none-25 {
  margin-top: -25px;
}

.mt-none-30 {
  margin-top: -30px;
}

.mt-none-35 {
  margin-top: -35px;
}

.mt-none-40 {
  margin-top: -40px;
}

.mt-none-45 {
  margin-top: -45px;
}

.mt-none-50 {
  margin-top: -50px;
}

.mt-none-55 {
  margin-top: -55px;
}

.mt-none-60 {
  margin-top: -60px;
}

.mt-none-65 {
  margin-top: -65px;
}

.mt-none-70 {
  margin-top: -70px;
}

.mt-none-75 {
  margin-top: -75px;
}

.mt-none-80 {
  margin-top: -80px;
}

.mt-none-85 {
  margin-top: -85px;
}

.mt-none-90 {
  margin-top: -90px;
}

.mt-none-95 {
  margin-top: -95px;
}

.mt-none-100 {
  margin-top: -100px;
}

/*-- Margin Top --*/
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-165 {
  margin-top: 165px;
}

.mt-170 {
  margin-top: 170px;
}

.mt-175 {
  margin-top: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mt-185 {
  margin-top: 185px;
}

.mt-190 {
  margin-top: 190px;
}

.mt-195 {
  margin-top: 195px;
}

.mt-200 {
  margin-top: 200px;
}

/*-- Margin Bottom --*/
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-155 {
  margin-bottom: 155px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-165 {
  margin-bottom: 165px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-175 {
  margin-bottom: 175px;
}

.mb-180 {
  margin-bottom: 180px;
}

.mb-185 {
  margin-bottom: 185px;
}

.mb-190 {
  margin-bottom: 190px;
}

.mb-195 {
  margin-bottom: 195px;
}

.mb-200 {
  margin-bottom: 200px;
}

/*-- Margin Left --*/
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-65 {
  margin-left: 65px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-85 {
  margin-left: 85px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-115 {
  margin-left: 115px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-125 {
  margin-left: 125px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-155 {
  margin-left: 155px;
}

.ml-160 {
  margin-left: 160px;
}

.ml-165 {
  margin-left: 165px;
}

.ml-170 {
  margin-left: 170px;
}

.ml-175 {
  margin-left: 175px;
}

.ml-180 {
  margin-left: 180px;
}

.ml-185 {
  margin-left: 185px;
}

.ml-190 {
  margin-left: 190px;
}

.ml-195 {
  margin-left: 195px;
}

.ml-200 {
  margin-left: 200px;
}

/*-- Margin Right --*/
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-75 {
  margin-right: 75px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-85 {
  margin-right: 85px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-95 {
  margin-right: 95px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-105 {
  margin-right: 105px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-115 {
  margin-right: 115px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-125 {
  margin-right: 125px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-135 {
  margin-right: 135px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-145 {
  margin-right: 145px;
}

.mr-150 {
  margin-right: 150px;
}

.mr-155 {
  margin-right: 155px;
}

.mr-160 {
  margin-right: 160px;
}

.mr-165 {
  margin-right: 165px;
}

.mr-170 {
  margin-right: 170px;
}

.mr-175 {
  margin-right: 175px;
}

.mr-180 {
  margin-right: 180px;
}

.mr-185 {
  margin-right: 185px;
}

.mr-190 {
  margin-right: 190px;
}

.mr-195 {
  margin-right: 195px;
}

.mr-200 {
  margin-right: 200px;
}

/*-- Padding Top --*/
.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-175 {
  padding-top: 175px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

/*-- Padding Bottom --*/
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-165 {
  padding-bottom: 165px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-195 {
  padding-bottom: 195px;
}

.pb-200 {
  padding-bottom: 200px;
}

/*-- Padding Left --*/
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-55 {
  padding-left: 55px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-65 {
  padding-left: 65px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-75 {
  padding-left: 75px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-85 {
  padding-left: 85px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-95 {
  padding-left: 95px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-105 {
  padding-left: 105px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-115 {
  padding-left: 115px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-125 {
  padding-left: 125px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-135 {
  padding-left: 135px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-145 {
  padding-left: 145px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-160 {
  padding-left: 160px;
}

.pl-165 {
  padding-left: 165px;
}

.pl-170 {
  padding-left: 170px;
}

.pl-175 {
  padding-left: 175px;
}

.pl-180 {
  padding-left: 180px;
}

.pl-185 {
  padding-left: 185px;
}

.pl-190 {
  padding-left: 190px;
}

.pl-195 {
  padding-left: 195px;
}

.pl-200 {
  padding-left: 200px;
}

/*-- Padding Right --*/
.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-55 {
  padding-right: 55px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-65 {
  padding-right: 65px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-75 {
  padding-right: 75px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-85 {
  padding-right: 85px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-95 {
  padding-right: 95px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-105 {
  padding-right: 105px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-115 {
  padding-right: 115px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-125 {
  padding-right: 125px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-135 {
  padding-right: 135px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-145 {
  padding-right: 145px;
}

.pr-150 {
  padding-right: 150px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-160 {
  padding-right: 160px;
}

.pr-165 {
  padding-right: 165px;
}

.pr-170 {
  padding-right: 170px;
}

.pr-175 {
  padding-right: 175px;
}

.pr-180 {
  padding-right: 180px;
}

.pr-185 {
  padding-right: 185px;
}

.pr-190 {
  padding-right: 190px;
}

.pr-195 {
  padding-right: 195px;
}

.pr-200 {
  padding-right: 200px;
}

/* typography css start */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1.3;
  color: var(--color-heading);
  font-weight: 500;
  font-family: var(--font-heading);
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

/* typography css end */
@keyframes jump {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40% {
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes jump {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40% {
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes rotated {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotated {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotatedHalf {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  50% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@keyframes rotatedHalf {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  50% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@keyframes rotatedHalfTwo {
  0% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@keyframes rotatedHalfTwo {
  0% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@keyframes scale-upOne {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
  }
}

@keyframes scale-upOne {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
  }
}

@keyframes scale-right {
  0% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  50% {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }

  100% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@keyframes scale-right {
  0% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  50% {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }

  100% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0.7;
  }

  40% {
    opacity: 1;
  }

  100% {
    opacity: 0.7;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0.7;
  }

  40% {
    opacity: 1;
  }

  100% {
    opacity: 0.7;
  }
}

@keyframes hvr-ripple-out {
  0% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  100% {
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
  }
}

@keyframes hvr-ripple-out-two {
  0% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  100% {
    top: -18px;
    right: -18px;
    bottom: -18px;
    left: -18px;
    opacity: 0;
  }
}

@keyframes scale-up-one {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  40% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-one {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  40% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-two {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  40% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}

@keyframes scale-up-two {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  40% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}

@keyframes scale-up-three {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }

  40% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }

  100% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
}

@keyframes scale-up-three {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }

  40% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }

  100% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
}

@keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    -moz-transform: translate(0px, 0px) rotate(0deg);
    -ms-transform: translate(0px, 0px) rotate(0deg);
    -o-transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    transform: translate(73px, -1px) rotate(36deg);
    -webkit-transform: translate(73px, -1px) rotate(36deg);
    -moz-transform: translate(73px, -1px) rotate(36deg);
    -ms-transform: translate(73px, -1px) rotate(36deg);
    -o-transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    transform: translate(141px, -20px) rotate(72deg);
    -webkit-transform: translate(141px, -20px) rotate(72deg);
    -moz-transform: translate(141px, -20px) rotate(72deg);
    -ms-transform: translate(141px, -20px) rotate(72deg);
    -o-transform: translate(141px, -20px) rotate(72deg);
  }

  60% {
    transform: translate(83px, -60px) rotate(108deg);
    -webkit-transform: translate(83px, -60px) rotate(108deg);
    -moz-transform: translate(83px, -60px) rotate(108deg);
    -ms-transform: translate(83px, -60px) rotate(108deg);
    -o-transform: translate(83px, -60px) rotate(108deg);
  }

  80% {
    transform: translate(-40px, 72px) rotate(144deg);
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
    -moz-transform: translate(-40px, 72px) rotate(144deg);
    -ms-transform: translate(-40px, 72px) rotate(144deg);
    -o-transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    transform: translate(0px, 0px) rotate(0deg);
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    -moz-transform: translate(0px, 0px) rotate(0deg);
    -ms-transform: translate(0px, 0px) rotate(0deg);
    -o-transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes animationFramesTwo {
  0% {
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }

  20% {
    transform: translate(73px, -1px) rotate(36deg) scale(0.9);
  }

  40% {
    transform: translate(141px, 72px) rotate(72deg) scale(1);
  }

  60% {
    transform: translate(83px, 122px) rotate(108deg) scale(1.2);
  }

  80% {
    transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
  }

  100% {
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
}

@keyframes animationFramesTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1);
  }

  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg) scale(0.9);
  }

  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg) scale(1);
  }

  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg) scale(1.2);
  }

  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
}

@keyframes animationFramesThree {
  0% {
    transform: translate(165px, -30px);
    -webkit-transform: translate(165px, -30px);
    -moz-transform: translate(165px, -30px);
    -ms-transform: translate(165px, -30px);
    -o-transform: translate(165px, -30px);
  }

  100% {
    transform: translate(-60px, 80px);
    -webkit-transform: translate(-60px, 80px);
    -moz-transform: translate(-60px, 80px);
    -ms-transform: translate(-60px, 80px);
    -o-transform: translate(-60px, 80px);
  }
}

@keyframes animationFramesThree {
  0% {
    transform: translate(165px, -30px);
    -webkit-transform: translate(165px, -30px);
    -moz-transform: translate(165px, -30px);
    -ms-transform: translate(165px, -30px);
    -o-transform: translate(165px, -30px);
  }

  100% {
    transform: translate(-60px, 80px);
    -webkit-transform: translate(-60px, 80px);
    -moz-transform: translate(-60px, 80px);
    -ms-transform: translate(-60px, 80px);
    -o-transform: translate(-60px, 80px);
  }
}

@keyframes animationFramesFour {
  0% {
    transform: translate(0px, 60px) rotate(0deg);
    -webkit-transform: translate(0px, 60px) rotate(0deg);
    -moz-transform: translate(0px, 60px) rotate(0deg);
    -ms-transform: translate(0px, 60px) rotate(0deg);
    -o-transform: translate(0px, 60px) rotate(0deg);
  }

  100% {
    transform: translate(-100px, -100px) rotate(180deg);
    -webkit-transform: translate(-100px, -100px) rotate(180deg);
    -moz-transform: translate(-100px, -100px) rotate(180deg);
    -ms-transform: translate(-100px, -100px) rotate(180deg);
    -o-transform: translate(-100px, -100px) rotate(180deg);
  }
}

@keyframes animationFramesFour {
  0% {
    transform: translate(0px, 60px) rotate(0deg);
    -webkit-transform: translate(0px, 60px) rotate(0deg);
    -moz-transform: translate(0px, 60px) rotate(0deg);
    -ms-transform: translate(0px, 60px) rotate(0deg);
    -o-transform: translate(0px, 60px) rotate(0deg);
  }

  100% {
    transform: translate(-100px, -100px) rotate(180deg);
    -webkit-transform: translate(-100px, -100px) rotate(180deg);
    -moz-transform: translate(-100px, -100px) rotate(180deg);
    -ms-transform: translate(-100px, -100px) rotate(180deg);
    -o-transform: translate(-100px, -100px) rotate(180deg);
  }
}

@keyframes animationFramesFive {
  0% {
    transform: translate(0, 0) rotate(0deg);
    -webkit-transform: translate(0, 0) rotate(0deg);
    -moz-transform: translate(0, 0) rotate(0deg);
    -ms-transform: translate(0, 0) rotate(0deg);
    -o-transform: translate(0, 0) rotate(0deg);
  }

  21% {
    transform: translate(4px, -20px) rotate(38deg);
    -webkit-transform: translate(4px, -20px) rotate(38deg);
    -moz-transform: translate(4px, -20px) rotate(38deg);
    -ms-transform: translate(4px, -20px) rotate(38deg);
    -o-transform: translate(4px, -20px) rotate(38deg);
  }

  41% {
    transform: translate(-50px, -60px) rotate(74deg);
    -webkit-transform: translate(-50px, -60px) rotate(74deg);
    -moz-transform: translate(-50px, -60px) rotate(74deg);
    -ms-transform: translate(-50px, -60px) rotate(74deg);
    -o-transform: translate(-50px, -60px) rotate(74deg);
  }

  60% {
    transform: translate(-20px, -30px) rotate(108deg);
    -webkit-transform: translate(-20px, -30px) rotate(108deg);
    -moz-transform: translate(-20px, -30px) rotate(108deg);
    -ms-transform: translate(-20px, -30px) rotate(108deg);
    -o-transform: translate(-20px, -30px) rotate(108deg);
  }

  80% {
    transform: translate(-195px, -49px) rotate(144deg);
    -webkit-transform: translate(-195px, -49px) rotate(144deg);
    -moz-transform: translate(-195px, -49px) rotate(144deg);
    -ms-transform: translate(-195px, -49px) rotate(144deg);
    -o-transform: translate(-195px, -49px) rotate(144deg);
  }

  100% {
    transform: translate(-1px, 0px) rotate(180deg);
    -webkit-transform: translate(-1px, 0px) rotate(180deg);
    -moz-transform: translate(-1px, 0px) rotate(180deg);
    -ms-transform: translate(-1px, 0px) rotate(180deg);
    -o-transform: translate(-1px, 0px) rotate(180deg);
  }
}

@keyframes animationFramesFive {
  0% {
    transform: translate(0, 0) rotate(0deg);
    -webkit-transform: translate(0, 0) rotate(0deg);
    -moz-transform: translate(0, 0) rotate(0deg);
    -ms-transform: translate(0, 0) rotate(0deg);
    -o-transform: translate(0, 0) rotate(0deg);
  }

  21% {
    transform: translate(4px, -20px) rotate(38deg);
    -webkit-transform: translate(4px, -20px) rotate(38deg);
    -moz-transform: translate(4px, -20px) rotate(38deg);
    -ms-transform: translate(4px, -20px) rotate(38deg);
    -o-transform: translate(4px, -20px) rotate(38deg);
  }

  41% {
    transform: translate(-50px, -60px) rotate(74deg);
    -webkit-transform: translate(-50px, -60px) rotate(74deg);
    -moz-transform: translate(-50px, -60px) rotate(74deg);
    -ms-transform: translate(-50px, -60px) rotate(74deg);
    -o-transform: translate(-50px, -60px) rotate(74deg);
  }

  60% {
    transform: translate(-20px, -30px) rotate(108deg);
    -webkit-transform: translate(-20px, -30px) rotate(108deg);
    -moz-transform: translate(-20px, -30px) rotate(108deg);
    -ms-transform: translate(-20px, -30px) rotate(108deg);
    -o-transform: translate(-20px, -30px) rotate(108deg);
  }

  80% {
    transform: translate(-195px, -49px) rotate(144deg);
    -webkit-transform: translate(-195px, -49px) rotate(144deg);
    -moz-transform: translate(-195px, -49px) rotate(144deg);
    -ms-transform: translate(-195px, -49px) rotate(144deg);
    -o-transform: translate(-195px, -49px) rotate(144deg);
  }

  100% {
    transform: translate(-1px, 0px) rotate(180deg);
    -webkit-transform: translate(-1px, 0px) rotate(180deg);
    -moz-transform: translate(-1px, 0px) rotate(180deg);
    -ms-transform: translate(-1px, 0px) rotate(180deg);
    -o-transform: translate(-1px, 0px) rotate(180deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.zoominup {
  animation-name: zoominup;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.zoominup2 {
  animation-name: zoominup;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes zoominup {
  0% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

@keyframes updown {
  0% {
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
  }

  50% {
    transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
  }

  100% {
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
  }
}

@keyframes updown-2 {
  0% {
    transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
  }

  50% {
    transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
  }

  100% {
    transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
  }
}

@keyframes ltr {
  0% {
    width: 0;
  }

  15% {
    width: 95%;
  }

  85% {
    opacity: 1;
  }

  90% {
    width: 95%;
    opacity: 0;
  }

  to {
    width: 0;
    opacity: 0;
  }
}

/*circleAnimation*/
@keyframes circleAnimation {

  0%,
  100% {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    -webkit-transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }

  34% {
    border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    -webkit-transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
    transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
  }

  50% {
    -webkit-transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }

  67% {
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
    -webkit-transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
    transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
  }
}

@keyframes icon-bounce {

  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  60% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

@keyframes icon-bounce {

  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  60% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

@keyframes lr-animation {
  0% {
    -webkit-transform: translateX(40px);
    -ms-transform: translateX(40px);
    transform: translateX(40px);
  }

  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes tb-animation {
  0% {
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes xb_up_down {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-20px);
  }
}

.slide-up-down {
  animation: xb_up_down 1s ease infinite alternate;
}

@keyframes xb_ltr {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateX(-20px);
  }
}

.slide-ltr {
  animation: xb_ltr 1s ease infinite alternate;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes zoom {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }

  100% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
}

@keyframes zoom {
  0% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }

  50% {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }

  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: rotate(7deg);
    -ms-transform: rotate(7deg);
    transform: rotate(7deg);
  }

  50% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(7deg);
    -ms-transform: rotate(7deg);
    transform: rotate(7deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: rotate(7deg);
    -ms-transform: rotate(7deg);
    transform: rotate(7deg);
  }

  50% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(7deg);
    -ms-transform: rotate(7deg);
    transform: rotate(7deg);
  }
}

@keyframes down {
  0% {
    -webkit-transform: translateY(5px);
    -ms-transform: translateY(5px);
    transform: translateY(5px);
  }

  50% {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    -webkit-transform: translateY(5px);
    -ms-transform: translateY(5px);
    transform: translateY(5px);
  }
}

@keyframes down {
  0% {
    -webkit-transform: translateY(5px);
    -ms-transform: translateY(5px);
    transform: translateY(5px);
  }

  50% {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    -webkit-transform: translateY(5px);
    -ms-transform: translateY(5px);
    transform: translateY(5px);
  }
}

@keyframes outer-ripple {
  0% {
    transform: scale(1);
    filter: alpha(opacity=50);
    opacity: 0.5;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -webkit-filter: alpha(opacity=50);
  }

  80% {
    transform: scale(1.5);
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
  }

  100% {
    transform: scale(2.5);
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(2.5);
    -moz-transform: scale(2.5);
    -ms-transform: scale(2.5);
    -o-transform: scale(2.5);
  }
}

@keyframes outer-ripple {
  0% {
    transform: scale(1);
    filter: alpha(opacity=50);
    opacity: 0.5;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }

  80% {
    transform: scale(2.5);
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(2.5);
    -moz-transform: scale(2.5);
    -ms-transform: scale(2.5);
    -o-transform: scale(2.5);
  }

  100% {
    transform: scale(3.5);
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(3.5);
    -moz-transform: scale(3.5);
    -ms-transform: scale(3.5);
    -o-transform: scale(3.5);
  }
}

@-moz-keyframes outer-ripple {
  0% {
    transform: scale(1);
    filter: alpha(opacity=50);
    opacity: 0.5;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }

  80% {
    transform: scale(2.5);
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(2.5);
    -moz-transform: scale(2.5);
    -ms-transform: scale(2.5);
    -o-transform: scale(2.5);
  }

  100% {
    transform: scale(3.5);
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(3.5);
    -moz-transform: scale(3.5);
    -ms-transform: scale(3.5);
    -o-transform: scale(3.5);
  }
}

@keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes pulse-border {
  0% {
    transform: scale(1);
    opacity: 0.67;
  }

  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}

@keyframes pulse-border {
  0% {
    transform: scale(1);
    opacity: 0.67;
  }

  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}

@keyframes pulse-border-big {
  0% {
    transform: scale(1);
    opacity: 0.67;
  }

  100% {
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes pulse-border-big {
  0% {
    transform: scale(1);
    opacity: 0.67;
  }

  100% {
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes tada {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  10%,
  20% {
    -webkit-transform: scale(0.9) rotate(-5deg);
    transform: scale(0.9) rotate(-5deg);
  }

  50%,
  50%,
  70%,
  90% {
    -webkit-transform: scale(1.1) rotate(5deg);
    transform: scale(1.1) rotate(5deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale(1.1) rotate(-5deg);
    transform: scale(1.1) rotate(-5deg);
  }

  100% {
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}

@keyframes tada {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  10%,
  20% {
    -webkit-transform: scale(0.9) rotate(-5deg);
    -ms-transform: scale(0.9) rotate(-5deg);
    transform: scale(0.9) rotate(-5deg);
  }

  50%,
  50%,
  70%,
  90% {
    -webkit-transform: scale(1.1) rotate(5deg);
    -ms-transform: scale(1.1) rotate(5deg);
    transform: scale(1.1) rotate(5deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale(1.1) rotate(-5deg);
    -ms-transform: scale(1.1) rotate(-5deg);
    transform: scale(1.1) rotate(-5deg);
  }

  100% {
    -webkit-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}

.wow.skewIn.animated {
  -webkit-animation-name: xbSkewIn;
  animation-name: xbSkewIn;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: .7s;
  animation-duration: .7s;
  -webkit-animation-timing-function: cubic-bezier(0.67, 0.04, 0.3, 0.91);
  animation-timing-function: cubic-bezier(0.67, 0.04, 0.3, 0.91);
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@keyframes xbSkewIn {
  0% {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0);
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes xbSkewIn {
  0% {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0);
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.skewInImg {
  clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
  display: inline-block;
  position: relative;
  overflow: hidden;
  transition: 1.3s cubic-bezier(0.5, 0.5, 0, 1);
}

.skewInImg img {
  transform-origin: 50% 50%;
  transition: 1.3s cubic-bezier(0.5, 0.5, 0, 1);
  max-width: 100%;
  height: auto;
  transform: scale(1.5) translate(100px, 0px);
}

.skewInImg.animated {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.skewInImg.animated img {
  transform: scale(1) translate(0px, 0px);
}

[data-aos=fade-up] {
  transform: translateY(50px);
}

[data-aos=fade-down] {
  transform: translateY(-50px);
}

[data-aos=fade-right] {
  transform: translate(-50px);
}

[data-aos=fade-left] {
  transform: translate(50px);
}

[data-aos=fade-up-right] {
  transform: translate(-50px, 50px);
}

[data-aos=fade-up-left] {
  transform: translate(50px, 50px);
}

[data-aos=fade-down-right] {
  transform: translate(-50px, -50px);
}

[data-aos=fade-down-left] {
  transform: translate(50px, -50px);
}

[data-aos][data-aos][data-aos-easing=ease],
body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: cubic-bezier(0.18, 0.57, 0.25, 0.97);
}

@keyframes xb-danceTop {
  16% {
    -webkit-transform: skew(-14deg);
    transform: skew(-14deg);
  }

  33% {
    -webkit-transform: skew(12deg);
    transform: skew(12deg);
  }

  49% {
    -webkit-transform: skew(-8deg);
    transform: skew(-8deg);
  }

  66% {
    -webkit-transform: skew(6deg);
    transform: skew(6deg);
  }

  83% {
    -webkit-transform: skew(-4deg);
    transform: skew(-4deg);
  }
}

@keyframes xb-danceTop {
  16% {
    -webkit-transform: skew(-14deg);
    transform: skew(-14deg);
  }

  33% {
    -webkit-transform: skew(12deg);
    transform: skew(12deg);
  }

  49% {
    -webkit-transform: skew(-8deg);
    transform: skew(-8deg);
  }

  66% {
    -webkit-transform: skew(6deg);
    transform: skew(6deg);
  }

  83% {
    -webkit-transform: skew(-4deg);
    transform: skew(-4deg);
  }
}

@keyframes xbzoominzoomup {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.8);
  }
}

@keyframes rotateme {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }

  50% {
    transform: rotate(-180deg);
  }

  100% {
    transform: rotate(-360deg);
    opacity: 1;
  }
}

@keyframes rotateme2 {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}

.rotateme {
  animation-name: rotateme;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.rotateme2 {
  animation-name: rotateme2;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/*--
    - Overlay
------------------------------------------*/
[data-overlay] {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

[data-overlay]::before {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 100%;
}

/*-- Overlay Color --*/
[data-overlay="light"]::before {
  background-color: var(--color-white);
}

[data-overlay="dark"]::before {
  background-color: var(--color-black);
}

/*-- Overlay Opacity --*/
[data-opacity="1"]::before {
  opacity: 0.1;
}

[data-opacity="2"]::before {
  opacity: 0.2;
}

[data-opacity="3"]::before {
  opacity: 0.3;
}

[data-opacity="4"]::before {
  opacity: 0.4;
}

[data-opacity="5"]::before {
  opacity: 0.5;
}

[data-opacity="6"]::before {
  opacity: 0.6;
}

[data-opacity="7"]::before {
  opacity: 0.7;
}

[data-opacity="8"]::before {
  opacity: 0.8;
}

[data-opacity="9"]::before {
  opacity: 0.9;
}

.header-style-one {
  background: var(--color-white);
  border-bottom: 1px solid #edebe9;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0);
}

.header-style-three .main-menu ul li a {
  color: var(--xb-track-color);
}

.header-area {
  position: relative;
  z-index: 5;
}

@media (max-width: 991px) {
  .header-area .xb-header {
    padding: 10px 0;
  }
}

.header-contact a {
  border-radius: 5px;
  background: #0b3948;
  box-shadow: 0 4px 4px 0 #f5f5f5;
  padding: 0px 30px;
  color: var(--color-white);
  display: inline-block;
  line-height: 1;
}

.header-contact a:hover {
  background-color: var(--color-yellow);
  color: var(--color-primary);
  -webkit-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  transform: translateY(-2px);
}

.header-contact a:hover svg path {
  fill: var(--color-primary);
}

.header-contact a svg {
  margin-right: 6px;
  -webkit-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  transform: translateY(-2px);
}

.header-contact a svg path {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.header-contact a span {
  font-weight: 600;
  font-size: 22px;
  line-height: 273%;
  letter-spacing: -0.02em;
}

.contact-two a {
  border-radius: 50px;
  background: transparent;
  padding: 17px 30px;
  border: 1px solid #E8E8E8;
}

.contact-two a:hover {
  border-color: var(--color-yellow);
}

.contact-two a span {
  color: var(--color-heading);
  line-height: 1;
}

.contact-three a {
  border-radius: 60px;
  background: var(--xb-track-color);
  box-shadow: none;
}

.contact-three a:hover {
  background-color: #1140c0;
}

.contact-three a span {
  color: var(--color-white);
}

.side-menu a {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.side-menu a span {
  position: absolute;
  left: 47px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-transform: uppercase;
  color: var(--color-white);
  font-size: 14px;
  font-weight: 600;
  padding-left: 8px;
}

.side-menu a svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}

.side-menu a svg path {
  fill: var(--color-primary);
}

.header-style-two#xb-header-area .xb-header-area-sticky {
  padding: 15px 0;
  background-color: #16171B;
}

.header-style-two .side-menu {
  margin-left: 27px;
  padding-right: 45px;
}

.header-style-two .xb-header__top {
  border-bottom: 1px solid #FFFFFF0F;
  padding: 14px 0;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .header-style-two .xb-header__top {
    display: none;
  }
}

.header-style-two .xb-header__top .top-list li {
  font-size: 14px;
  font-weight: 600;
  color: #FFFFFF;
  letter-spacing: -0.21px;
  text-transform: uppercase;
}

.header-style-two .xb-header__midle {
  margin-left: auto;
  margin-right: 191px;
}

@media (max-width: 1199px) {
  .header-style-two .xb-header__midle {
    margin-right: auto;
  }
}

@media (max-width: 767px) {
  .header-style-two .xb-header__midle {
    display: none;
  }
}

.header-style-two .xb-header__midle li {
  list-style: none;
  display: inline-block;
  margin-left: 9px;
}

.header-style-two .xb-header__midle li a {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2.94px;
  text-transform: uppercase;
  text-decoration-line: underline;
}

@media (max-width: 767px) {
  .header-style-two .xb-header__btn {
    display: none;
  }
}

.header-style-two .xb-header__btn a {
  margin-left: 30px;
  padding: 19px 27px;
}

.header-style-two .xb-header__btn a.thm-btn--stroke {
  padding: 17px 27px;
}

@media (max-width: 991px) {
  .header-style-two .xb-header__btn a.thm-btn--stroke {
    display: none;
  }
}

.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 3;
}

.header-style-three {
  z-index: 3;
  background-color: var(--color-white);
}

.header-style-three .xb-left-header .header-logo {
  margin-right: 250px;
}

@media (max-width: 767px) {
  .header-style-three .xb-left-header .header-logo {
    margin-right: 0;
  }
}

@media (max-width: 1199px) {
  .header-style-three .xb-header {
    padding: 10px 0;
  }
}

.header-style-three .header-bar {
  width: 78px;
  height: 26px;
  position: relative;
  margin-left: auto;
  margin-right: 206px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .header-style-three .header-bar {
    margin-right: auto;
    margin-left: -80px;
  }
}

@media (max-width: 1199px) {
  .header-style-three .header-bar {
    margin-right: auto;
    margin-left: 0;
  }
}

@media (max-width: 991px) {
  .header-style-three .header-bar {
    margin-right: auto;
    margin-left: -100px;
  }
}

@media (max-width: 767px) {
  .header-style-three .header-bar {
    margin-right: 0;
    margin-left: auto;
  }
}

.header-style-three .header-bar span {
  width: 100%;
  height: 2px;
  background: #020202;
  position: absolute;
  display: inline-block;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.header-style-three .header-bar span:nth-child(1) {
  top: 0;
}

.header-style-three .header-bar span:nth-child(2) {
  top: 10px;
}

.header-style-three .header-bar span:nth-child(3) {
  bottom: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .header-style-three .header-btn {
    margin-left: 40px;
  }
}

@media (max-width: 1199px) {
  .header-style-three .header-btn {
    margin-left: 40px;
  }
}

@media (max-width: 767px) {
  .header-style-three .header-btn {
    display: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .header-style-three .main-menu ul li:not(:last-child) {
    margin-right: 32px;
  }
}

.header-bg::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 43.8%;
  height: 100%;
  content: "";
  background-color: var(--color-primary);
  z-index: -1;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .header-bg::before {
    width: 34%;
  }
}

@media (max-width: 1199px) {
  .header-bg::before {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .header-bg::before {
    width: 100%;
  }
}

#xb-header-area .xb-header-area-sticky .main-menu>ul>li>a {
  padding: 37px 0;
}

#xb-header-area.header-style-three .xb-header-area-sticky .main-menu>ul>li>a {
  padding: 30px 0;
}

#xb-header-area .xb-header-area-sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  top: 0;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  visibility: hidden;
  background: var(--color-white);
}

#xb-header-area .xb-header-area-sticky.xb-header-fixed {
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  visibility: visible;
  -webkit-box-shadow: 0 3px 18px rgba(6, 27, 92, 0.09);
  -khtml-box-shadow: 0 3px 18px rgba(6, 27, 92, 0.09);
  -moz-box-shadow: 0 3px 18px rgba(6, 27, 92, 0.09);
  -ms-box-shadow: 0 3px 18px rgba(6, 27, 92, 0.09);
  -o-box-shadow: 0 3px 18px rgba(6, 27, 92, 0.09);
  box-shadow: 0 3px 18px rgba(6, 27, 92, 0.09);
}

.xb-header_btn a {
  font-family: var(--font-heading);
  line-height: 28px;
  letter-spacing: -0.16px;
  color: var(--color-primary);
  padding: 10px 20px;
  border: 1px solid var(--color-primary);
}

.side-menu-4 {
  margin-left: 40px;
}

@media (max-width: 991px) {
  .header-style-two .hero__content-wrap {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.header-top-inner {
  background-color: #5F2656;
  padding-left: 70px;
  padding-right: 70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .header-top-inner {
    padding: 0;
  }
}

@media (max-width: 1199px) {
  .header-top-inner {
    padding: 0;
  }
}

.header-top {
  padding: 0 0 7px;
}

@media (max-width: 767px) {
  .header-top {
    justify-content: center;
  }
}

.header-top li {
  color: var(--color-white);
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-top: 7px;
}

.header-top li img {
  margin-right: 8px;
}

.header-style-four {
  margin-left: -80px;
  margin-right: -80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .header-style-four {
    margin: 0 -15px;
  }
}

@media (max-width: 1199px) {
  .header-style-four {
    margin: 0;
  }
}

.header-style-four .header__wrap {
  padding-left: 70px;
  padding-right: 70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .header-style-four .header__wrap {
    padding: 0;
  }
}

@media (max-width: 1199px) {
  .header-style-four .header__wrap {
    padding: 0;
  }
}

.header-style-four .header-contact a {
  background-color: #E8F1F4;
  border: 1px solid #D8EEF5;
  color: #006676;
}

.header-style-four .header-contact a svg path {
  fill: #006676;
}

.header-style-four .header-contact a:hover {
  background-color: #006676;
  color: #fff;
}

.header-style-four .header-contact a:hover svg path {
  fill: #fff;
}

.main-menu {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.main-menu ul {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.main-menu ul li {
  position: relative;
}

.main-menu ul li:not(:last-child) {
  margin-right: 56px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .main-menu ul li:not(:last-child) {
    margin-right: 45px;
  }
}

@media (max-width: 1199px) {
  .main-menu ul li:not(:last-child) {
    margin-right: 20px;
  }
}

.main-menu ul li .submenu li {
  margin-right: 0;
}

.main-menu ul li a {
  display: inline-block;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: var(--color-heading);
  letter-spacing: 1.96px;
  padding: 37px 0;
  position: relative;
  line-height: 22px;
  letter-spacing: -0.02px;
}

.main-menu ul li a span i {
  font-size: 11px;
  margin-left: 5px;
}

.main-menu ul li.menu-item-has-children>a span::after {
  content: "+";
  display: inline-block;
  padding-left: 4px;
}

.main-menu ul li.menu-item-has-children:hover>.submenu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.main-menu ul li .submenu {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  min-width: 260px;
  top: 100%;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  left: 0;
  padding: 21px 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 3;
  top: calc(100% + 10px);
  text-align: left;
  box-shadow: 0 0.5rem 1.875rem rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.main-menu ul li .submenu li a {
  padding: 8px 32px;
  display: block;
  margin: 0;
  font-size: 16px;
  letter-spacing: 0;
  font-weight: 600;
}

.main-menu ul li .submenu ul {
  left: 100%;
  top: 0px;
}

.main-menu ul li .submenu ul::before {
  display: none;
}

.main-menu ul li.menu-last ul.submenu {
  right: 0;
  left: auto;
}

.main-menu ul li.menu-last ul.submenu ul {
  right: auto;
  left: -100%;
}

.main-menu ul li ul.submenu .menu-item-has-children>a::after {
  position: absolute;
  top: 9px;
  right: 15px;
  content: "\f105";
  font-size: 13px;
  font-family: 'Font Awesome 5 Pro';
}

.section-title .sub-title {
  color: var(--color-heading);
  font-weight: 500;
  line-height: 30px;
  font-size: 12px;
  padding: 0 10px;
  margin-bottom: 18px;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0 4px 13px 0 #e9e9e4;
}

.section-title .title {
  color: var(--color-heading);
  font-size: 54px;
  letter-spacing: -0.02em;
  line-height: 64px;
  display: inline-block;
}

@media (max-width: 1199px) {
  .section-title .title {
    font-size: 44px;
    letter-spacing: -0.01em;
    line-height: 56px;
  }
}

@media (max-width: 767px) {
  .section-title .title {
    font-size: 32px;
    letter-spacing: 0;
    line-height: 38.5px;
  }
}

.section-title .title span {
  z-index: 1;
}

.section-title .title span svg {
  position: absolute;
  top: 0px;
  left: -4px;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.section-title .content {
  max-width: 571px;
  line-height: 28px;
}

.section-title .sub-title-two {
  color: var(--color-heading);
  font-weight: 500;
  font-size: 15px;
  background: none;
  box-shadow: none;
  position: relative;
  padding-left: 27px;
  margin-bottom: 12px;
  display: inline-block;
  text-transform: uppercase;
}

.section-title .sub-title-two::after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  background-image: url(../img/icon/sub-icon.png);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: left center;
}

.section-title .title-two {
  color: var(--color-white);
  line-height: 60px;
}

@media (max-width: 767px) {
  .section-title .title-two {
    line-height: 40px;
  }
}

.section-title .content-two {
  color: var(--color-white);
  font-size: 20px;
}

.section-title--three .sub-title {
  color: var(--xb-track-color);
  font-weight: 500;
  font-size: 14px;
  background: none;
  box-shadow: none;
  position: relative;
  padding-left: 27px;
  margin-bottom: 9px;
  display: inline-block;
}

.section-title--three .sub-title::after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  background-image: url(../img/icon/sub-icon02.png);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: left center;
}

.section-title--three .title {
  font-size: 54px;
  line-height: 64px;
  color: var(--xb-track-color);
}

@media (max-width: 991px) {
  .section-title--three .title {
    font-size: 40px;
    line-height: 50px;
  }
}

@media (max-width: 767px) {
  .section-title--three .title {
    font-size: 32px;
    line-height: 40px;
  }
}

.section-title--three .chg-title {
  font-size: 70px;
  line-height: 80px;
}

@media (max-width: 1199px) {
  .section-title--three .chg-title {
    font-size: 50px;
    line-height: 60px;
  }
}

@media (max-width: 991px) {
  .section-title--three .chg-title {
    font-size: 35px;
    line-height: 45px;
  }
}

.section-title--three .content {
  color: var(--color-default-two);
}

.section-title--md .title {
  font-size: 45px;
  line-height: 55px;
}

@media (max-width: 991px) {
  .section-title--md .title {
    font-size: 35px;
    line-height: 45px;
  }
}

@media (max-width: 767px) {
  .section-title--md .title {
    font-size: 30px;
    line-height: 38px;
  }
}

.title-senior-care .xb-heading {
  font-size: 60px;
  font-weight: 400;
  letter-spacing: -0.03em;
}

@media (max-width: 1199px) {
  .title-senior-care .xb-heading {
    font-size: 45px;
    letter-spacing: -0.02em;
  }
}

@media (max-width: 767px) {
  .title-senior-care .xb-heading {
    font-size: 32px;
    letter-spacing: 0;
  }
}

.title-senior-care p {
  color: #656565;
}

.about-content_wrap {
  background: var(--color-primary);
  border-radius: 20px;
  padding: 100px 0 97px 70px;
  margin-left: -70px;
  margin-right: -70px;
  z-index: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .about-content_wrap {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 1199px) {
  .about-content_wrap {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .about-content_wrap {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 991px) {
  .about-content_wrap {
    padding-top: 40px;
  }
}

.about-content_wrap .xb-shape .shape {
  position: absolute;
  z-index: -1;
}

.about-content_wrap .xb-shape .shape--one {
  top: 0;
  left: 0;
}

.about-content_wrap .xb-shape .shape--two {
  left: 0;
  bottom: 0;
}

.about-content_wrap .xb-shape .shape--three {
  top: 0;
  right: 0;
}

.about-content_wrap .xb-shape .shape--four {
  right: 0;
  bottom: 0;
}

.about-left .title-white {
  color: var(--color-white);
}

.about-left .bg-color {
  background: #21596d;
  box-shadow: none;
  color: var(--color-white);
}

.about-left .content {
  color: #d8e3f8;
}

.xb-reating .xb-item--holder {
  margin-right: 50px;
}

.xb-reating .xb-item--img {
  margin-bottom: 16px;
}

@media (max-width: 1199px) {
  .xb-reating .xb-item--img img {
    height: 24px;
  }
}

.xb-reating .xb-item--review {
  font-size: 20px;
  color: #d8e3f8;
}

.xb-reating .xb-item--review:not(:last-child) {
  padding-right: 20px;
}

.xb-reating .xb-item--review i {
  color: var(--color-yellow);
}

.xb-map-location {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

@media (max-width: 991px) {
  .xb-map-location {
    left: 20px;
  }
}

@media (max-width: 767px) {
  .xb-map-location {
    z-index: 2;
    left: 60px;
    top: -22px;
  }
}

.xb-map-location .xb-loc_circle {
  height: 36px;
  width: 36px;
  border-radius: 100px;
  background: rgba(255, 212, 112, 0.1);
  position: relative;
  left: 202px;
  position: absolute;
  left: 102px;
  top: 103px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  cursor: pointer;
}

.xb-map-location .xb-loc_circle::after,
.xb-map-location .xb-loc_circle::before {
  content: '';
  position: absolute;
  border-radius: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.xb-map-location .xb-loc_circle::after {
  height: 22px;
  width: 22px;
  background: rgba(255, 212, 112, 0.2);
}

.xb-map-location .xb-loc_circle::before {
  height: 8px;
  width: 8px;
  background: var(--color-yellow);
  z-index: 2;
}

.xb-map-location .xb-loc_circle:hover,
.xb-map-location .xb-loc_circle.active {
  background: rgba(255, 212, 112, 0.8);
  transform: scale(2);
}

.xb-map-location .xb-loc_circle:hover::after,
.xb-map-location .xb-loc_circle.active::after {
  background: rgba(255, 212, 112, 0.2);
  border: 1px solid #0b3948;
}

.xb-map-location .xb-loc_circle:hover::before,
.xb-map-location .xb-loc_circle.active::before {
  background: rgba(255, 212, 112, 0.8);
  border: 2px solid #0b3948;
}

.xb-map-location .xb-loc_circle:hover~.xb-map_inner,
.xb-map-location .xb-loc_circle.active~.xb-map_inner {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.xb-map-wrapper .xb-map-location:nth-child(2) {
  left: auto;
  right: -50px;
  z-index: 2;
  top: -24px;
}

@media (max-width: 767px) {
  .xb-map-wrapper .xb-map-location:nth-child(2) {
    z-index: 1;
  }
}

.xb-map-wrapper .xb-map-location:nth-child(3) {
  left: auto;
  right: 27px;
  top: 140px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .xb-map-wrapper .xb-map-location:nth-child(3) {
    right: 0;
  }
}

@media (max-width: 767px) {
  .xb-map-wrapper .xb-map-location:nth-child(3) {
    top: 106px;
    left: auto;
    right: -105px;
  }
}

.xb-map_inner {
  background: var(--color-white);
  border-radius: 10px;
  max-width: 402px;
  display: flex;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  top: 157px;
  left: -111px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
}

@media (max-width: 767px) {
  .xb-map_inner {
    width: 400px;
  }
}

.xb-map_inner::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 13px 12px 0px 0px;
  border-color: transparent #fff;
  position: absolute;
  left: 222px;
  top: -13px;
  visibility: visible;
  opacity: 1;
  transition: all 300ms ease-in-out 0ms;
}

.xb-map_inner .xb-item--img img {
  border-radius: 10px 0 0 10px;
}

.xb-map_inner .xb-item--holder {
  padding-left: 30px;
  padding-right: 35px;
  display: inline-block;
}

.xb-map_inner .xb-item--title {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  font-family: var(--font-body);
  padding-bottom: 5px;
}

.xb-map_inner .xb-item--content {
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 8px;
}

.xb-map_inner .xb-item--loc-dir {
  color: var(--color-primary);
}

.xb-map_inner .xb-item--loc-dir a {
  color: currentColor;
}

.xb-map_inner .xb-item--loc-dir span {
  padding-left: 7px;
}

.about-content .about-right_img {
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

@media (max-width: 991px) {
  .about-content .about-right_img {
    position: unset;
  }
}

.about-content .con-size {
  font-size: 20px;
}

.ec-about-left .title {
  margin-top: -10px;
}

.ec-about-left .content {
  border-top: 1px solid #1140c0;
  padding-top: 22px;
  margin-left: -20px;
  max-width: 385px;
}

@media (max-width: 991px) {
  .ec-about-left .content {
    margin-left: 0;
    max-width: 100%;
  }
}

.ec-about-left .about-play_video {
  border-radius: 20px;
  overflow: hidden;
  display: inline-block;
}

.ec-about-left .about-play_video a {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ec-about-right {
  text-align: center;
  margin-left: -20px;
  width: auto;
  height: auto;
  border-radius: 20px;
  padding: 45px 30px 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .ec-about-right {
    margin-left: 0;
  }
}

@media (max-width: 1199px) {
  .ec-about-right {
    margin-left: 0;
  }
}

.ec-about-right .xb-item--holder {
  border-bottom: 1px dashed var(--color-white);
  padding-bottom: 25px;
  margin-bottom: 35px;
}

.ec-about-right .xb-item--number {
  font-size: 70px;
  line-height: 80px;
  letter-spacing: -0.02em;
  color: var(--color-white);
  padding-bottom: 8px;
}

.ec-about-right .xb-item--text {
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: var(--color-white);
}

.ec-about-right .ec-about-btn {
  justify-content: center;
}

.ec-about-right .ec-about-btn a:hover {
  background-color: #06083C;
}

.about-shape .shape {
  position: absolute;
}

.about-shape .shape--one {
  bottom: 46%;
  left: 121px;
}

.about-shape .shape--two {
  top: 46px;
  right: 139px;
}

.about-shape .shape--three {
  bottom: 119px;
  right: 179px;
}

.about-funfact .xb-item--top {
  padding-bottom: 25px;
  margin-bottom: 15px;
  border-bottom: 1px solid #D9D9D9;
}

.about-funfact .xb-item--icon {
  margin-right: 10px;
}

.about-funfact .xb-item--title {
  text-transform: uppercase;
}

.about-funfact .xb-item--number {
  font-size: 70px;
}

.about-inner-wrap {
  padding-left: 70px;
}

@media (max-width: 1199px) {
  .about-inner-wrap {
    padding-left: 30px;
  }
}

@media (max-width: 991px) {
  .about-inner-wrap {
    padding-left: 0;
  }
}

.sc-about-content {
  background-color: #5F2656;
  padding: 90px 60px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  height: 100%;
}

@media (max-width: 1199px) {
  .sc-about-content {
    padding: 40px;
  }
}

.sc-about-content h3 {
  font-size: 32px;
  color: var(--color-white);
  line-height: 40px;
}

.sc-about-content h4 {
  font-size: 20px;
  font-weight: 600;
  font-family: var(--font-body);
  color: var(--color-white);
}

.sc-about-content p,
.sc-about-content ul>li {
  font-size: 18px;
  color: #D9BFD5;
  font-weight: 500;
  line-height: 28px;
}

.sc-about-content ul>li:not(:last-child) {
  margin-bottom: 3px;
}

.sc-about-img {
  border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;
  border: 1px solid #BEBEBE;
}

@media (max-width: 1199px) {
  .sc-about-content-inner {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .sc-about-logo {
    max-width: 80px;
  }
}

.brand .border-line {
  position: relative;
}

.brand .border-line::before {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  background: #e8e4de;
  top: 37%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: -1;
}

.brand .brand-sub_title {
  background: var(--color-white);
  border: 1px solid #e8e4de;
  padding: 8px 20px;
  color: var(--color-default);
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  box-shadow: none;
}

.brand .brand-sub_title b {
  color: var(--color-heading);
  font-weight: 700;
}

.brand .brand-sub_title.chng-mr {
  margin: 0;
  z-index: 2;
  position: relative;
  color: var(--color-default-two);
}

.brand .brand-sub_title.chng-mr b {
  color: var(--xb-track-color);
}

.ec-brand-slider {
  box-shadow: 0 9px 45px 0 #f1f1e9;
  background: #fff;
  border-radius: 10px;
  padding: 74px 0px 50px;
  margin-top: -22px;
}

.ec-brand-slider .brand-logo {
  justify-content: center;
  display: flex;
  align-items: center;
}

.service-white {
  background: var(--color-white);
}

.xb-services {
  border-radius: 20px;
  border: 1px solid #eee5e5;
  padding: 45px 40px 50px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.xb-services::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: var(--color-primary);
  opacity: 0;
  -webkit-transform: scaleY(0.96);
  transform: scale(0.96);
  transform-origin: bottom;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  bottom: 0;
  right: 0;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.xb-services::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: var(--color-white);
  opacity: 1;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

.xb-services .xb-item--title {
  font-weight: 600;
  font-size: 26px;
  line-height: 28px;
  letter-spacing: -0.02em;
  font-family: var(--font-body);
  padding-bottom: 20px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.xb-services .xb-item--img {
  margin-top: 33px;
  margin-bottom: 30px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  overflow: hidden;
  border-radius: 20px;
}

.xb-services .xb-item--img img:nth-child(1) {
  display: inline-block;
  visibility: visible;
  opacity: 1;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.xb-services .xb-item--img img:nth-child(2) {
  visibility: hidden;
  opacity: 0;
  display: none;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.xb-services .xb-item--button .srv-btn {
  color: var(--color-primary);
  display: flex;
  align-items: center;
}

.xb-services .xb-item--button .srv-btn span {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background: #fff;
  box-shadow: 0 1px 6px 0 rgba(93, 106, 131, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.xb-services .xb-item--button .srv-btn span svg {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.xb-services:hover::after {
  opacity: 1;
  transform: scaleY(1);
}

.xb-services:hover::before {
  opacity: 0;
}

.xb-services:hover .xb-item--title {
  color: var(--color-white);
}

.xb-services:hover .xb-item--content {
  color: #d8e3f8;
}

.xb-services:hover .xb-item--img img:nth-child(1) {
  display: none;
  visibility: hidden;
  opacity: 0;
}

.xb-services:hover .xb-item--img img:nth-child(2) {
  display: inline-block;
  visibility: visible;
  opacity: 1;
}

.xb-services:hover .xb-item--button .srv-btn {
  color: var(--color-white);
}

.xb-services:hover .xb-item--button .srv-btn span {
  background: var(--color-yellow);
}

.xb-services:hover .xb-item--button .srv-btn span svg {
  transform: rotate(45deg);
}

.service-title .title {
  display: inline-block;
}

.service-title .title span svg {
  left: -7px;
}

.srv-heading {
  align-items: flex-end;
}

.srv-heading .srv-content {
  max-width: 543px;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-white);
  padding-bottom: 10px;
  margin-right: 15px;
}

.xb-service-two {
  border-radius: 20px;
  background: var(--color-white);
  padding: 20px 20px 20px 20px;
}

.xb-service-two:hover .xb-item--img img {
  -webkit-transform: scale(1.08);
  -ms-transform: scale(1.08);
  transform: scale(1.08);
}

.xb-service-two .xb-item--title {
  font-family: var(--font-body);
  font-weight: 600;
  font-size: 26px;
  line-height: 38px;
  padding-bottom: 5px;
}

@media (max-width: 991px) {
  .xb-service-two .xb-item--title {
    font-size: 22px;
  }
}

.xb-service-two .xb-item--title a {
  color: currentColor;
}

.xb-service-two .xb-item--img {
  overflow: hidden;
  display: inline-block;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.xb-service-two .xb-item--img img {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.xb-service-two .xb-item--img::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 0%;
  width: 100%;
  background: rgba(11, 57, 72, 0.7);
  opacity: 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.xb-service-two .xb-item--srv-link {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-yellow);
  height: 50px;
  width: 50px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.xb-service-two:hover .xb-item--img::after {
  opacity: 1;
  height: 100%;
}

.xb-service-two:hover .xb-item--srv-link {
  top: 50%;
  opacity: 1;
  visibility: visible;
}

.ec-service {
  background: #f5f5f5;
  border-radius: 20px;
  padding: 10px 10px 20px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.ec-service .xb-item--holder {
  text-align: center;
  background: var(--color-white);
  border-radius: 20px;
  padding: 40px 30px;
}

.ec-service .xb-item--icon {
  height: 76px;
}

.ec-service .xb-item--title {
  font-family: var(--font-body);
  font-weight: 600;
  font-size: 26px;
  line-height: 28px;
  color: var(--xb-track-color);
  padding: 40px 0 20px;
}

.ec-service:hover {
  background: #1140c0;
}

.ec-service:hover .ec-btn .ec-srv {
  box-shadow: none;
}

.ec-btn {
  justify-content: center;
}

.ec-btn .ec-srv {
  padding: 17px 38px;
  box-shadow: 0 6px 12px 0 #e8e8e8;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.color-white {
  color: var(--color-white) !important;
}

.service-shape .shape {
  position: absolute;
}

.service-shape .shape--one {
  top: 43%;
  left: 89px;
}

.service-shape .shape--two {
  bottom: 30%;
  right: 145px;
}

.xb-service-three {
  background-color: var(--color-white);
  padding: 72px 40px 50px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.xb-service-three:hover {
  background-color: #1140C0;
}

.xb-service-three:hover .xb-item--icon-bg svg path {
  fill: #fff;
}

.xb-service-three:hover .xb-item--link img {
  filter: brightness(100);
}

.xb-service-three:hover .xb-item--title,
.xb-service-three:hover .xb-item--content,
.xb-service-three:hover .xb-item--link {
  color: #fff;
}

.xb-service-three .xb-item--icon {
  position: relative;
  margin-bottom: 60px;
  z-index: 1;
}

.xb-service-three .xb-item-icon-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.xb-service-three .xb-item-icon-bg svg path {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.xb-service-three .xb-item--title {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 18px;
}

.xb-service-three .xb-item--title a {
  color: currentColor;
}

.xb-service-three .xb-item--content {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.xb-service-three .xb-item--link {
  font-size: 18px;
  color: #06083C;
  margin-top: 30px;
  display: inline-block;
  position: relative;
}

.xb-service-three .xb-item--link img {
  margin-left: 9px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.xb-service-three .xb-item--link::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: currentColor;
  content: "";
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.xb-service-shape .shape {
  position: absolute;
  z-index: -1;
}

.xb-service-shape .shape--1 {
  left: 4%;
  top: 20%;
}

.xb-service-shape .shape--2 {
  right: 5%;
  bottom: 16%;
}

.service-img {
  margin-right: -30px;
}

.service-img img {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .service-img {
    margin-right: 0;
  }
}

@media (max-width: 1199px) {
  .service-img {
    margin-right: 0;
  }
}

.service-content-inner {
  padding-left: 75px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .service-content-inner {
    padding-left: 30px;
  }
}

@media (max-width: 1199px) {
  .service-content-inner {
    padding-left: 0;
  }
}

.xb-list li {
  font-size: 18px;
  color: var(--color-primary);
}

.xb-list li:not(:last-child) {
  margin-bottom: 12px;
}

.xb-list li i {
  margin-right: 10px;
}

.service-bottom-content h3 {
  font-size: 22px;
  font-family: var(--font-body);
  margin-bottom: 28px;
}

[class*="unordered_list"] {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.iconbox_block {
  padding: 50px 50px 42px;
  transition: .3s;
  background-color: var(--color-white);
  box-shadow: 0 6px 12px 0 #e8e8e8;
}

.iconbox_block:hover {
  transform: translateY(-2px);
}

.iconbox_block:hover .iconbox_icon {
  background-color: var(--color-yellow);
}

.iconbox_block .iconbox_icon {
  flex: 0 0 auto;
  margin: 0 0 32px;
  border-radius: 100%;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  color: var(--color-primary);
  background-color: #F7F5F2;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.iconbox_block .iconbox_icon img {
  max-width: 40px;
}

.iconbox_block.layout_icon_left {
  padding: 30px;
  display: inline-flex;
  align-items: flex-start;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.iconbox_block.layout_icon_left .iconbox_icon {
  width: 70px;
  height: 70px;
  margin: 0 30px 0 0;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.iconbox_block.layout_icon_left .iconbox_icon img {
  max-width: 32px;
}

.service_facilities_group {
  margin: -10px;
}

.service_facilities_group>li {
  padding: 10px;
  flex: 0 0 50%;
}

@media (max-width: 767px) {
  .service_facilities_group>li {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .service_facilities_group>li {
    flex: 0 0 50%;
  }
}

.service_facilities_group .iconbox_block.layout_icon_left {
  display: flex;
  align-items: center;
  padding: 16px 20px 16px 16px;
}

.service_facilities_group .iconbox_block.layout_icon_left .iconbox_icon {
  width: 42px;
  height: 42px;
  margin: 0 16px 0 0;
  border-radius: 6px;
}

.service_facilities_group .iconbox_block.layout_icon_left .iconbox_icon img {
  max-width: 22px;
}

.service_facilities_group .iconbox_block.layout_icon_left .iconbox_title {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 12px;
  font-weight: 600;
  font-family: var(--font-body);
  letter-spacing: -0.02em;
  color: var(--color-primary);
}

.service_facilities_group .iconbox_block.layout_icon_left:hover .iconbox_title {
  color: var(--color-primary);
}

.sc-service-item {
  background-color: var(--color-white);
  padding: 70px 40px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

@media (max-width: 1199px) {
  .sc-service-item {
    padding: 50px 30px;
  }
}

.sc-service-item .xb-item--icon {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sc-service-item .xb-item--title {
  font-size: 30px;
  margin-bottom: 35px;
}

@media (max-width: 1199px) {
  .sc-service-item .xb-item--title {
    font-size: 25px;
    margin-bottom: 25px;
  }
}

.sc-service-item .xb-item--title a {
  color: currentColor;
}

.sc-service-item .xb-item--content {
  margin-bottom: 50px;
}

.sc-service-item .xb-item--link {
  background-color: transparent;
  border: 1px solid #DBDBDB;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  color: #222222;
  padding: 15px 70px;
}

@media (max-width: 1199px) {
  .sc-service-item .xb-item--link {
    padding: 14px 35px;
  }
}

.sc-service-item .xb-item--link svg path {
  stroke: #222222;
}

.sc-service-item .xb-item--link:hover {
  background-color: #006676;
  border-color: #006676;
  color: #fff;
}

.sc-service-item .xb-item--link:hover svg {
  -webkit-transform: translate(0);
  -ms-transform: translate(0);
  transform: translate(0);
}

.sc-service-item .xb-item--link:hover svg path {
  stroke: #fff;
  fill: none;
}

.xb-work-wrapper .work-shape {
  position: absolute;
  top: 98px;
  left: 308px;
}

.xb-work {
  max-width: 298px;
  text-align: center;
}

@media (max-width: 767px) {
  .xb-work {
    margin-left: 0;
  }
}

.xb-work .xb-item--img {
  display: inline-block;
  border-radius: 20px;
  overflow: hidden;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.xb-work .xb-item--img img {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.xb-work .xb-item--title {
  font-family: var(--font-body);
  font-weight: 600;
  font-size: 26px;
  line-height: 28px;
  padding-bottom: 17px;
}

.xb-work .xb-item--content {
  font-size: 16px;
}

.xb-work .xb-item--number {
  position: absolute;
  height: 40px;
  width: 40px;
  top: 78px;
  right: 3px;
  background: var(--color-white);
  color: var(--color-primary);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5ecfb;
  border-radius: 100%;
  box-shadow: 0 4px 13px 0 rgba(11, 57, 72, 0.25);
}

.xb-work .xb-item--number.number02 {
  top: 236px;
  left: 129px;
}

.xb-work .xb-item--number.number03 {
  top: 78px;
  left: 3px;
}

@media (max-width: 991px) {
  .xb-work .xb-item--number.number03 {
    top: 120px;
    left: -20px;
  }
}

@media (max-width: 991px) {
  .xb-work .xb-item--number.number03 {
    left: auto;
  }
}

.xb-work:hover .xb-item--img img {
  transform: scale(1.1);
}

.ec-work {
  text-align: center;
}

.ec-work .xb-item--number {
  font-family: var(--font-heading);
  font-weight: 500;
  font-size: 70px;
  color: var(--xb-track-color);
  position: absolute;
  left: 27%;
  bottom: 60px;
}

.ec-work .xb-item--title {
  font-family: var(--font-body);
  font-weight: 600;
  font-size: 26px;
  line-height: 28px;
  color: var(--xb-track-color);
  padding: 16px 0 20px;
}

.ec-work .xb-item--content {
  max-width: 330px;
  display: inline-block;
}

.work-shape .shape {
  position: absolute;
}

.work-shape .shape--one {
  bottom: 0;
  left: 83px;
}

.xb-cta {
  border-radius: 20px;
  padding: 61px 0 70px 70px;
  background: var(--color-primary);
  width: auto;
}

@media (max-width: 767px) {
  .xb-cta {
    padding: 40px 30px;
  }
}

.xb-cta .xb-item--title {
  font-family: var(--font-body);
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: var(--color-white);
}

.xb-cta .xb-item--content {
  color: #d8e3f8;
  padding: 25px 0 38px 0;
  max-width: 458px;
}

.xb-cta.cta-left {
  margin-left: -70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .xb-cta.cta-left {
    margin-left: 0;
  }
}

@media (max-width: 1199px) {
  .xb-cta.cta-left {
    margin-left: 0;
  }
}

.xb-cta.cta-right {
  margin-right: -70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .xb-cta.cta-right {
    margin-right: 0;
  }
}

@media (max-width: 1199px) {
  .xb-cta.cta-right {
    margin-right: 0;
  }
}

.xb-cta-list {
  list-style: none;
  padding-bottom: 55px;
}

.xb-cta-list .list-title {
  font-weight: 600;
  font-size: 22px;
  color: var(--color-white);
  padding-bottom: 28px;
  display: inline-block;
}

.xb-cta-list .cta-item {
  color: var(--color-white);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.xb-cta-list .cta-item:last-child {
  margin-bottom: 0;
}

.xb-cta-list .cta-item svg {
  margin-right: 10px;
}

@media (max-width: 767px) {
  .cta-button a {
    font-size: 16px;
    padding: 22px 20px;
  }
}

.cta-button .thm-white {
  background: var(--color-white);
}

.cta-box {
  margin-right: -70px;
  margin-left: -70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .cta-box {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 1199px) {
  .cta-box {
    margin-left: 0;
    margin-right: 0;
  }
}

.cta-wrap {
  padding: 60px 0 70px 70px;
  border-radius: 20px;
  margin-bottom: -148px;
  z-index: 2;
}

@media (max-width: 767px) {
  .cta-wrap {
    padding: 50px 30px;
  }
}

.cta-wrap .xb-item--title {
  font-size: 34px;
  line-height: 44px;
  color: var(--color-white);
}

.cta-wrap .xb-item--sub-title {
  font-size: 16px;
  letter-spacing: -0.01em;
  color: #d8e3f8;
}

.cta-wrap .xb-item--img {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (max-width: 1199px) {
  .cta-wrap .xb-item--img {
    max-width: 450px;
  }
}

@media (max-width: 991px) {
  .cta-wrap .xb-item--img {
    max-width: 300px;
  }
}

@media (max-width: 767px) {
  .cta-wrap .xb-item--img {
    display: none;
  }
}

.cta-wrap .cta-button .cta-btn img {
  margin-right: 10px;
}

.xb-feature {
  border: 1px solid #f2f6ff;
  border-radius: 10px;
  box-shadow: 0 4px 13px 0 #e9e9e4;
  background: var(--color-white);
  padding: 15px 70px;
  padding-top: 0;
  margin-left: 0px;
  margin-right: 0px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .xb-feature {
    margin-left: 0;
    margin-right: 0;
  }
}

.xb-feature .xb-item--holder {
  margin-top: 15px;
}

.xb-feature .xb-item--icon {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  background: #f7f5f2;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.xb-feature .xb-item--icon::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  border-radius: 100%;
  border: 1px dashed var(--color-primary);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  visibility: hidden;
  opacity: 0;
}

.xb-feature .xb-item--title {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: var(--color-heading);
  padding-left: 10px;
}

.xb-feature .xb-item--holder:hover .xb-item--icon::before {
  visibility: visible;
  opacity: 1;
  animation: spin 20s infinite linear;
  -webkit-animation: spin 20s infinite linear;
}

.xb-feature-two .xb-item--inner {
  box-shadow: 0 8px 15px 0 rgba(220, 220, 220, 0.25);
  background: #fff;
  border-radius: 10px;
  padding: 22px 31px 28px;
}

.xb-feature-two .xb-item--img {
  padding-right: 52px;
}

.xb-feature-two .xb-item--holder {
  transform: translateY(4px);
}

.xb-feature-two .xb-item--title {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: -0.03em;
  color: var(--color-heading);
  padding-bottom: 8px;
  display: inline-block;
}

.xb-feature-two .xb-item--text {
  max-width: 195px;
}

.feature-img img {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.xb-testimonial {
  border-radius: 20px;
  background: var(--color-white);
  border: 1px solid #f2f6ff;
  box-shadow: 0 4px 13px 0 #e9e9e4;
  padding: 0 40px 52px 41px;
  margin-bottom: 85px;
}

.xb-testimonial .xb-item--img {
  border-radius: 0 0 85px 85px;
  width: 170px;
  height: 171px;
  background: var(--color-primary);
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.xb-testimonial .xb-item--img img {
  border-radius: 100%;
}

.xb-testimonial .xb-item--holder {
  transform: translateY(-6px);
}

.xb-testimonial .xb-item--title {
  font-family: var(--font-body);
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: var(--color-heading);
}

.xb-testimonial .xb-item--sub-title {
  font-size: 16px;
  line-height: 30px;
}

.xb-testimonial .xb-item--reating {
  padding-top: 5px;
}

.xb-testimonial .xb-item--reating i {
  color: var(--color-yellow);
  margin-right: 7px;
}

.xb-testimonial .xb-item--reating span {
  color: var(--color-heading);
  font-size: 20px;
  font-family: 600;
}

.xb-testimonial .xb-item--content {
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  margin-top: 30px;
}

.xb-testimonial .xb-tes_shape {
  position: absolute;
  top: 64.75px;
  right: 43px;
}

.testimonial-slider .swiper-pagination-bullet {
  display: inline-block;
  opacity: .1;
  height: 6px;
  width: 18px;
  border-radius: 20px;
  background: var(--color-primary);
}

.testimonial-slider .swiper-pagination-bullet-active {
  opacity: 1;
  background: var(--color-primary);
}

.testimonial-carousel .xb-swiper-arrow {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  box-shadow: 0 1px 6px 0 rgba(93, 106, 131, 0.25);
  z-index: 1;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.testimonial-carousel .xb-swiper-arrow:hover {
  background: var(--color-primary);
}

.testimonial-carousel .xb-swiper-arrow:hover img {
  filter: brightness(10000%);
}

.testimonial-carousel .xb-swiper-arrow-prev {
  position: absolute;
  left: 12%;
  top: 35.5%;
  transform: translateY(-50%);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .testimonial-carousel .xb-swiper-arrow-prev {
    left: 10px;
  }
}

@media (max-width: 1199px) {
  .testimonial-carousel .xb-swiper-arrow-prev {
    left: 10px;
  }
}

.testimonial-carousel .xb-swiper-arrow-next {
  position: absolute;
  right: 12%;
  top: 35.5%;
  transform: translateY(-50%);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .testimonial-carousel .xb-swiper-arrow-next {
    right: 10px;
  }
}

@media (max-width: 1199px) {
  .testimonial-carousel .xb-swiper-arrow-next {
    right: 10px;
  }
}

.dc-testimonial-slider {
  padding-bottom: 65px;
}

.dc-testimonial-slider .testimonial_wrapper {
  opacity: 0;
  -webkit-transform: translateX(60px);
  -ms-transform: translateX(60px);
  transform: translateX(60px);
}

.dc-testimonial-slider .swiper-slide-active .testimonial_wrapper {
  opacity: 1;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.dc-testimonial-slider-nav {
  max-width: 222px;
  padding: 0 15px;
  padding-bottom: 90px;
}

.dc-testimonial-slider-nav .swiper-slide-active .dc-testimonial-nav-item .xb-item--content,
.dc-testimonial-slider-nav .swiper-slide-active .dc-testimonial-nav-item .xb-item--avatar::before {
  opacity: 1;
}

.dc-testimonial-slider-nav .swiper-slide-active .dc-testimonial-nav-item .xb-item--avatar::before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.dc-testimonial-slider-nav .swiper-slide-active .dc-testimonial-nav-item .xb-item--name,
.dc-testimonial-slider-nav .swiper-slide-active .dc-testimonial-nav-item .xb-item--desig {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.dc-testimonial-slider-nav .swiper-slide-active .dc-testimonial-nav-item .xb-item--desig {
  transition-delay: .1s;
}

.dc-testimonial-nav-item {
  text-align: center;
}

.dc-testimonial-nav-item .xb-item--avatar {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dc-testimonial-nav-item .xb-item--avatar img {
  width: 46px;
}

.dc-testimonial-nav-item .xb-item--avatar::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  content: "";
  border: 1px solid #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  opacity: 0;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.dc-testimonial-nav-item .xb-item--content {
  position: absolute;
  opacity: 0;
  bottom: -71px;
  width: 200px;
  left: 27px;
  transform: translateX(-50%);
  text-align: center;
}

.dc-testimonial-nav-item .xb-item--name {
  color: #fff;
  font-size: 22px;
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  margin-bottom: 2px;
}

.dc-testimonial-nav-item .xb-item--desig {
  font-size: 16px;
  color: #C5BCC7;
  opacity: 0;
  display: inline-block;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
}

.dc-testimonial-slider-button {
  position: absolute;
  top: 14%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  color: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  width: 50px;
  height: 50px;
  border: 1px solid #7c3488;
  box-shadow: 0 1px 6px 0 rgba(93, 106, 131, 0.25);
  font-size: 26px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.dc-testimonial-slider-button:hover {
  color: var(--color-primary);
  background-color: var(--color-yellow);
  border-color: var(--color-yellow);
}

.dc-testimonial-slider-button.tm-button-next {
  left: auto;
  right: 0;
}

.dc-testimonial-quote {
  position: absolute;
  top: -4px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.testimonial_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial_wrapper .xb-title {
  font-size: 34px;
  line-height: 48px;
  text-align: center;
  color: var(--color-white);
  max-width: 990px;
}

@media (max-width: 991px) {
  .testimonial_wrapper .xb-title {
    font-size: 26px;
    line-height: 46px;
  }
}

@media (max-width: 767px) {
  .testimonial_wrapper .xb-title {
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0;
  }
}

.dc-testimonial-carousel .xb-swiper-arrow {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background: #6a2875;
  border: 1px solid #7c3488;
  box-shadow: 0 1px 10px 0 #5f1f69;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  box-shadow: 0 1px 6px 0 rgba(93, 106, 131, 0.25);
  z-index: 1;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.dc-testimonial-carousel .xb-swiper-arrow i {
  color: var(--color-white);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.dc-testimonial-carousel .xb-swiper-arrow:hover {
  background: #ffcb05;
}

.dc-testimonial-carousel .xb-swiper-arrow:hover i {
  color: #6a2875;
}

.dc-testimonial-carousel .xb-swiper-arrow-prev {
  position: absolute;
  left: 0;
  top: 30%;
}

.dc-testimonial-carousel .xb-swiper-arrow-next {
  position: absolute;
  right: 0;
  top: 30%;
}

.ec-testimonial-wrpper {
  border-radius: 20px;
  overflow: hidden;
  margin-top: -408px;
  padding: 145px 70px 70px;
}

@media (max-width: 767px) {
  .ec-testimonial-wrpper {
    padding: 50px 20px;
  }
}

.ec-testimonial-slider .swiper-pagination {
  display: flex;
  position: unset;
  padding-top: 62px;
}

.ec-testimonial-slider .swiper-pagination .swiper-pagination-bullet {
  background: var(--color-white);
  opacity: .2;
}

.ec-testimonial-slider .swiper-pagination .swiper-pagination-bullet-active {
  opacity: 1;
  background: var(--color-white);
}

.ec-testimonial .qutation {
  height: 66px;
  width: 66px;
  background: #1140c0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.ec-testimonial .content {
  font-size: 36px;
  line-height: 54px;
  max-width: 955px;
  padding-bottom: 70px;
  color: var(--color-white);
}

@media (max-width: 991px) {
  .ec-testimonial .content {
    font-size: 26px;
    line-height: 44px;
  }
}

@media (max-width: 767px) {
  .ec-testimonial .content {
    font-size: 20px;
    line-height: 40px;
  }
}

.ec-testimonial .text {
  font-size: 20px;
  color: var(--color-white);
}

@media (max-width: 1199px) {
  .testimonial-inner {
    padding: 0 100px;
  }
}

@media (max-width: 767px) {
  .testimonial-inner {
    padding: 0 50px;
  }
}

.testimonial-title .sub-title {
  background-color: #21596D;
  box-shadow: none;
  color: var(--color-white);
}

.testimonial-nav-two .dc-testimonial-slider-button {
  background-color: var(--color-white);
  color: var(--color-primary);
}

.testimonial-nav-two .dc-testimonial-slider-button:hover {
  background-color: var(--color-yellow);
  border-color: #7c3488;
  color: #7c3488;
}

.sc-testimonial-nav {
  margin-left: -18px;
}

.sc-testimonial-nav .dc-testimonial-nav-item .xb-item--content {
  text-align: left;
}

.sc-testimonial-nav .dc-testimonial-nav-item .xb-item--desig {
  color: var(--color-white);
}

.sc-testimonial-slider .testimonial_wrapper .xb-title {
  max-width: 100%;
  text-align: left;
}

.sc-testimonial-slider .testimonial_wrapper .xb-title {
  font-size: 45px;
  line-height: 57px;
}

@media (max-width: 1199px) {
  .sc-testimonial-slider .testimonial_wrapper .xb-title {
    font-size: 35px;
    line-height: 55px;
  }
}

@media (max-width: 767px) {
  .sc-testimonial-slider .testimonial_wrapper .xb-title {
    font-size: 22px;
    line-height: 40px;
  }
}

.sc-testimonial-inner {
  padding: 0 30px;
}

.xb-blog {
  border-radius: 20px;
  background: var(--color-white);
  box-shadow: 0 4px 13px 0 #e9e9e4;
  overflow: hidden;
  margin: 0 -70px;
  margin-bottom: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .xb-blog {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 1199px) {
  .xb-blog {
    margin-left: 0;
    margin-right: 0;
  }
}

.xb-blog .xb-item--img {
  margin-right: 60px;
  display: inline-block;
  overflow: hidden;
}

.xb-blog .xb-item--img img {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

@media (max-width: 991px) {
  .xb-blog .xb-item--author {
    padding: 20px;
  }
}

.xb-blog .xb-item--inner {
  width: 84%;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .xb-blog .xb-item--inner {
    flex-wrap: nowrap;
  }
}

@media (max-width: 1199px) {
  .xb-blog .xb-item--inner {
    width: 75%;
    padding: 40px 0;
    flex-wrap: nowrap;
    padding-right: 10px;
  }
}

@media (max-width: 991px) {
  .xb-blog .xb-item--inner {
    width: 100%;
    flex-wrap: wrap;
  }
}

.xb-blog .xb-item--location {
  display: block;
}

.xb-blog .xb-item--location svg {
  margin-right: 8px;
  -webkit-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  transform: translateY(-3px);
}

.xb-blog .xb-item--location i {
  margin-right: 10px;
}

.xb-blog .xb-item--title {
  font-family: var(--font-body);
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  padding: 18px 0 14px;
}

@media (max-width: 1199px) {
  .xb-blog .xb-item--title {
    font-size: 22px;
  }
}

.xb-blog .xb-item--title a {
  color: currentColor;
}

.xb-blog .xb-item--holder {
  padding-bottom: 38px;
}

.xb-blog .xb-item--avater {
  position: relative;
  padding-right: 40px;
  margin-right: 30px;
  margin-top: 15px;
}

.xb-blog .xb-item--avater::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  border-right: 1px solid #e8e4de;
}

.xb-blog .xb-item--details {
  margin-top: 15px;
}

.xb-blog .xb-item--avt_img {
  margin-right: 11px;
}

.xb-blog .xb-item--avt_img img {
  border-radius: 100px;
}

.xb-blog .xb-item--sub-title {
  font-size: 16px;
  line-height: 24px;
}

.xb-blog .xb-item--name {
  font-family: var(--font-body);
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-heading);
}

.xb-blog .xb-item--button .blog-btn {
  padding: 17px 40px;
}

.xb-blog .xb-item--date {
  border-bottom: 1px dashed var(--color-white);
  margin-bottom: 35px;
}

.xb-blog .xb-item--date .number {
  font-size: 40px;
  line-height: 60px;
  color: var(--color-yellow);
  display: block;
}

.xb-blog .xb-item--date .year {
  display: block;
  padding-bottom: 30px;
  color: var(--color-white);
}

.xb-blog .xb-item--time span {
  color: var(--color-white);
}

.xb-blog .xb-item--time span i {
  margin-right: 7px;
}

.xb-blog .xb-item--schedule {
  text-align: center;
  background: var(--color-primary);
  padding: 68px 20px 79px;
  width: 16%;
}

@media (max-width: 1199px) {
  .xb-blog .xb-item--schedule {
    width: 25%;
  }
}

@media (max-width: 991px) {
  .xb-blog .xb-item--schedule {
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 27px;
    padding: 20px;
  }
}

.xb-blog .xb-item--shape {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

@media (max-width: 991px) {
  .xb-blog .xb-item--shape {
    width: 100%;
  }
}

.xb-blog .xb-item--shape img {
  height: 100%;
}

@media (max-width: 991px) {
  .xb-blog .xb-item--shape img {
    width: 100%;
    object-fit: cover;
  }
}

.xb-blog:hover .xb-item--img img {
  transform: scale(1.1);
}

.xb-blog--two {
  margin: 0;
  padding-right: 27px;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .xb-blog--two {
    padding-right: 0;
  }
}

.xb-blog--two .xb-item--img {
  width: 220px;
  margin-right: 70px;
}

@media (max-width: 767px) {
  .xb-blog--two .xb-item--img {
    width: 100%;
    margin-right: 0;
  }
}

.xb-blog--two .xb-item--author {
  width: calc(100% - 290px);
}

@media (max-width: 767px) {
  .xb-blog--two .xb-item--author {
    width: 100%;
  }
}

.xb-blog--two .xb-item--title {
  max-width: 320px;
  font-size: 22px;
  line-height: 34px;
  padding: 22px 0 30px;
}

.xb-blog--two .xb-item--location {
  display: block;
  padding-bottom: 7px;
  font-size: 16px;
  line-height: 24px;
}

.xb-blog--two .xb-item--location svg {
  margin-right: 10px;
}

.xb-blog--two .xb-item--blg_date {
  position: absolute;
  top: 100px;
  left: 170px;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  background: #6a2875;
  border: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.xb-blog--two .xb-item--blg_date span {
  text-align: center;
  font-size: 20px;
  line-height: 34px;
  letter-spacing: -0.03em;
  color: var(--color-white);
}

.xb-blog--two .xb-item--blg_date .number {
  font-size: 24px;
}

.xb-blog--two .blog-button .thm-btn--two {
  padding: 18px 30px;
  background: var(--color-white);
  border: 1px solid #e8e8e8;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.xb-blog--two .blog-button .thm-btn--two:hover {
  color: var(--color-primary);
  border-color: var(--color-yellow);
}

.xb-blog--two .blog-button .thm-btn--two:hover svg path {
  fill: var(--color-primary);
}

.xb-blog--two:hover .blog-button .thm-btn--two {
  background: var(--color-yellow);
}

.xb-blog--two .xb-item--inner {
  width: 100%;
}

.post-thumbnail-wrapper {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.post-thumbnail-wrapper img {
  -webkit-transition: 0.5s cubic-bezier(0.25, 1, 0.5, 1);
  -o-transition: 0.5s cubic-bezier(0.25, 1, 0.5, 1);
  transition: 0.5s cubic-bezier(0.25, 1, 0.5, 1);
}

.post-meta {
  margin-bottom: 20px;
  margin-top: -4px;
}

.post-meta li {
  font-size: 16px;
  line-height: 25px;
  margin-right: 50px;
  margin-top: 7px;
}

.post-meta li i {
  margin-right: 5px;
  color: #191B1E;
}

.post-meta li a {
  color: #787B84;
}

article .post-title {
  margin-top: 0;
  margin-bottom: 14px;
  font-size: 35px;
  letter-spacing: -0.02em;
  word-break: break-word;
}

@media (max-width: 767px) {
  article .post-title {
    font-size: 24px;
  }
}

article .post-title a {
  color: currentColor;
}

.post-read-more {
  margin-top: 35px;
}

.post-read-more .xb-btn {
  padding: 18px 40px;
}

.single-post-item {
  margin-bottom: 60px;
}

.single-post-item:hover .post-thumbnail-wrapper img {
  -webkit-transform: scale(1.08);
  -ms-transform: scale(1.08);
  transform: scale(1.08);
}

.post-details .post-thumb {
  margin-bottom: 30px;
}

.post-details h2 {
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .post-details h2 {
    font-size: 24px;
  }
}

.post-details h3 {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .post-details h3 {
    font-size: 22px;
  }
}

.post-details p {
  font-size: 18px;
  line-height: 30px;
}

.post-details p:not(:last-child) {
  margin-bottom: 30px;
}

blockquote {
  background-color: #F3F4F5;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 55px;
  padding-left: 130px;
  position: relative;
  font-family: var(--font-heading);
  margin: 40px 0;
}

@media (max-width: 767px) {
  blockquote {
    padding: 30px 55px;
    padding-left: 55px;
    padding-left: 100px;
  }
}

blockquote p {
  font-size: 20px;
  font-weight: 600;
  color: #0F172A;
  line-height: 34px;
  margin-bottom: 20px !important;
}

blockquote span {
  font-size: 18px;
  font-weight: 500;
  color: #787B84;
  padding-left: 27px;
  position: relative;
}

blockquote span::before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 17px;
  height: 1px;
  background-color: #787B84;
  content: "";
}

blockquote .quote {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px 0;
  -webkit-border-radius: 20px 0;
  -moz-border-radius: 20px 0;
  -ms-border-radius: 20px 0;
  -o-border-radius: 20px 0;
}

.post-info-list {
  padding-left: 40px;
}

@media (max-width: 1199px) {
  .post-info-list {
    padding-left: 0;
  }
}

.post-info-list li {
  font-size: 18px;
  color: #787B84;
}

.post-info-list li:not(:last-child) {
  margin-bottom: 18px;
}

.post-info-list li img {
  margin-right: 12px;
}

.post-tags .tags-links {
  margin-top: 10px;
}

.post-tags .tag-title {
  font-size: 20px;
  margin-right: 15px;
  margin-top: 20px;
  font-weight: 500;
  font-family: var(--font-body);
}

.social-share .post-share {
  margin-top: 20px;
}

.social-share li:not(:last-child) {
  margin-right: 20px;
}

.social-share li a {
  color: var(--color-primary);
  background-color: var(--color-white);
  font-size: 13px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 8px 15px 0 rgba(220, 220, 220, 0.25);
}

.social-share li a:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.social-share .title {
  font-size: 20px;
  margin-right: 15px;
  margin-top: 20px;
  font-weight: 600;
}

/* comment css */
.post-comments {
  padding-bottom: 80px;
}

.post-comments .title {
  font-size: 30px;
  margin-bottom: 30px;
  font-weight: 500;
}

.latest__comments .comments-box {
  border-top: 1px solid var(--color-border-2);
  padding: 30px 0;
}

.latest__comments .comments-text {
  overflow: hidden;
  font-size: 18px;
  line-height: 30px;
}

.latest__comments .comments-avatar {
  border-radius: 50%;
  float: left;
}

@media (max-width: 767px) {
  .latest__comments .comments-avatar {
    float: none;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .latest__comments .comments-avatar {
    float: left;
    margin-bottom: 0px;
  }
}

.latest__comments .comments-avatar img {
  border-radius: 50%;
  width: 80px !important;
  margin-right: 30px;
}

.latest__comments li:last-child .comments-box {
  padding-bottom: 0px;
}

.latest__comments li:first-child .comments-box {
  border-top: 0;
  padding-top: 0;
}

.latest__comments li.children {
  margin-left: 100px;
}

@media (max-width: 767px) {
  .latest__comments li.children {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .latest__comments li.children {
    margin-left: 50px;
  }
}

.latest__comments .avatar-name {
  margin-bottom: 15px;
  overflow: hidden;
  position: relative;
}

.latest__comments .avatar-name h5 {
  font-size: 20px;
  margin-bottom: 0px;
  font-weight: 600;
  letter-spacing: -0.2px;
  font-family: var(--font-body);
}

.latest__comments .avatar-name span {
  font-size: 15px;
  color: var(--color-default);
}

.latest__comments .reply {
  color: #787B84;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  line-height: 1;
  position: absolute;
  right: 0;
  margin-top: 0;
  top: 0;
  text-decoration: none;
  background: #fff;
  padding: 8px 20px;
  border: 1px solid #EEE5E5;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.latest__comments .reply:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.latest__comments--2 p {
  font-size: 14px;
  line-height: 24px;
  color: var(--color-black);
}

.sidebar-area {
  padding-left: 10px;
}

@media (max-width: 1199px) {
  .sidebar-area {
    padding-left: 0;
  }
}

.widget {
  background-color: var(--color-white);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  padding: 30px;
  box-shadow: 0 8px 15px 0 rgba(220, 220, 220, 0.25);
}

.widget__title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 25px;
  font-family: var(--font-body);
}

@media (max-width: 1199px) {
  .widget__title {
    font-size: 20px;
  }
}

.widget__search {
  position: relative;
}

.widget__search input {
  height: 60px;
  background-color: #F7F5F2;
  padding: 20px;
  border: 0;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

.widget__search input::-webkit-input-placeholder {
  color: #8F959F;
  opacity: 1;
}

.widget__search input::-moz-placeholder {
  color: #8F959F;
  opacity: 1;
}

.widget__search input:-ms-input-placeholder {
  color: #8F959F;
  opacity: 1;
}

.widget__search input:-moz-placeholder {
  color: #8F959F;
  opacity: 1;
}

.widget__search button {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--color-primary);
}

.widget__category li:not(:last-child) {
  margin-bottom: 13px;
  padding-bottom: 13px;
  border-bottom: 1px solid #E7E9EE;
}

.widget__category li a {
  position: relative;
  display: block;
  z-index: 1;
  color: #888686;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
}

.widget__category li a:hover,
.widget__category li a.active {
  color: var(--color-primary);
}

.widget__category li a i {
  font-size: 13px;
}

.widget__post-item:not(:last-child) {
  margin-bottom: 20px;
}

.widget__post-item .post-thumb {
  width: 90px;
  height: 90px;
  overflow: hidden;
  margin-right: 20px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.widget__post-item .post-thumb img {
  width: 100%;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  height: 100%;
  object-fit: cover;
}

.widget__post-item:hover .post-thumb img {
  -webkit-transform: scale(1.08);
  -ms-transform: scale(1.08);
  transform: scale(1.08);
}

.widget__post-item:hover .post-title a {
  background-size: 100% 100%;
}

.widget__post-item .post-meta {
  margin-bottom: 5px;
}

.widget__post-item .post-meta a {
  font-size: 12px;
  color: #4A4C51;
  position: relative;
}

.widget__post-item .post-meta a:not(:last-child) {
  margin-right: 9px;
  padding-right: 12px;
}

.widget__post-item .post-meta a:not(:last-child)::before {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: "";
  width: 1px;
  height: 15px;
  background-color: #E6E6E6;
}

.widget__post-item .post-meta a i {
  margin-right: 10px;
  color: #191B1E;
}

.widget__post-item .post-content {
  width: calc(100% - 110px);
}

.widget__post-item .post-content .post-title {
  font-size: 16px;
  font-family: var(--font-body);
  color: var(--color-black);
  font-weight: 600;
}

.widget__post-item .post-content .post-title a {
  color: inherit;
}

.comments-form {
  padding: 50px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  padding-top: 40px;
  background-color: var(--color-white);
  box-shadow: 0 8px 15px 0 rgba(220, 220, 220, 0.25);
}

@media (max-width: 767px) {
  .comments-form {
    padding: 30px 20px;
    padding-top: 25px;
  }
}

.comments-form .comment-heading {
  margin-bottom: 30px;
}

.comments-form .title {
  font-size: 32px;
  margin-bottom: 8px;
  font-weight: 500;
}

@media (max-width: 767px) {
  .comments-form .title {
    font-size: 26px;
  }
}

.comments-form .form input,
.comments-form .form textarea {
  height: 55px;
  margin-bottom: 20px;
  padding: 0 20px;
  width: 100%;
  font-size: 15px;
  color: var(--color-black);
  border-radius: 0px;
  background-color: #F7F5F2;
  border: 2px solid #F7F5F2;
  font-weight: 400;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding-left: 46px;
}

.comments-form .form input::-webkit-input-placeholder,
.comments-form .form textarea::-webkit-input-placeholder {
  color: #8F959F;
  opacity: 1;
}

.comments-form .form input::-moz-placeholder,
.comments-form .form textarea::-moz-placeholder {
  color: #8F959F;
  opacity: 1;
}

.comments-form .form input:-ms-input-placeholder,
.comments-form .form textarea:-ms-input-placeholder {
  color: #8F959F;
  opacity: 1;
}

.comments-form .form input:-moz-placeholder,
.comments-form .form textarea:-moz-placeholder {
  color: #8F959F;
  opacity: 1;
}

.comments-form .form input:focus,
.comments-form .form textarea:focus {
  border-color: var(--color-primary);
}

.comments-form .form textarea {
  height: 150px;
  padding-top: 14px;
}

.comments-form .form .xb-item--field {
  position: relative;
}

.comments-form .form .icon {
  position: absolute;
  top: 13px;
  left: 20px;
  z-index: 1;
}

.comments-form__btn button {
  padding: 18px 35px;
}

.xb-award {
  border-radius: 20px;
  background: var(--color-white);
  box-shadow: 0 62px 73px 0 #dad9d6;
  border: 1px solid #f2f6ff;
  overflow: hidden;
  margin-left: -70px;
  margin-right: -70px;
  padding: 0 70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .xb-award {
    margin-left: 0;
    margin-right: 0;
  }
}

.xb-award .xb-shape .shape--one {
  position: absolute;
  top: -7px;
  right: -50px;
}

.xb-award .xb-shape .shape--two {
  position: absolute;
  bottom: 22px;
  right: -50px;
}

.award-left {
  padding: 78px 0;
}

.brand_award {
  justify-content: end;
  margin-top: -9px;
  margin-bottom: -40px;
  max-height: 550px;
}

@media (max-width: 991px) {
  .brand_award {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.brand_award .xb-item--holder:not(:last-child) {
  margin-right: 30px;
}

.brand_award .xb-item--holder.change-holder {
  margin-top: -139px;
}

.brand_award .xb-item--item {
  border-radius: 10px;
  width: 160px;
  height: 180px;
  box-shadow: 0 8px 18px 0 #e4e4e4;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 991px) {
  .brand_award .xb-item--item {
    width: 140px;
  }
}

@media (max-width: 767px) {
  .brand_award .xb-item--item {
    width: 75px;
    height: 100px;
  }

  .brand_award .xb-item--item img {
    max-width: 40px;
  }
}

.brand_award .xb-item--item:not(:last-child) {
  margin-bottom: 30px;
}

.marquee-first {
  -webkit-animation: marquee 25s linear infinite;
  animation: marquee 25s linear infinite;
}

.marquee-2 {
  -webkit-animation: marquee2 25s linear infinite;
  animation: marquee2 25s linear infinite;
}

@keyframes marquee {
  from {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes marquee {
  from {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes marquee2 {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@keyframes marquee2 {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.xb-news-wrap {
  border-top: 1px solid #e8e4de;
  border-bottom: 1px solid #e8e4de;
  padding: 50px 0;
}

.xb-news {
  position: relative;
}

.xb-news.xb-line::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 1px;
  right: 0;
  top: 0;
  background: #d9d9d9;
}

@media (max-width: 991px) {
  .xb-news.xb-line::before {
    display: none;
  }
}

.xb-news .xb-item--img {
  border-radius: 20px;
  display: inline-block;
  overflow: hidden;
}

.xb-news .xb-item--img img {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.xb-news .xb-item--title {
  font-family: var(--font-body);
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  max-width: 333px;
}

.xb-news .xb-item--title a {
  color: currentColor;
}

.xb-news .xb-item--button .news-btn {
  color: var(--color-primary);
  display: flex;
  align-items: center;
}

.xb-news .xb-item--button .news-btn span {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background: #fff;
  box-shadow: 0 1px 6px 0 rgba(93, 106, 131, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.xb-news .xb-item--button .news-btn span svg {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.xb-news:hover .xb-item--img img {
  transform: scale(1.1);
}

.xb-news:hover .xb-item--button .news-btn span {
  background: var(--color-yellow);
}

.xb-news:hover .xb-item--button .news-btn span svg {
  transform: rotate(45deg);
}

.news-title {
  text-align: center;
}

.ec-news {
  background: var(--color-white);
  border-radius: 20px;
  padding: 10px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.ec-news:hover .xb-item--img img {
  -webkit-transform: scale(1.06);
  -ms-transform: scale(1.06);
  transform: scale(1.06);
}

.ec-news .xb-item--img {
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.ec-news .xb-item--img img {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.ec-news .xb-item--cat {
  font-size: 14px;
  color: var(--color-white);
  background: #06083c;
  border-radius: 30px;
  padding: 2px 20px;
  position: absolute;
  left: 30px;
  top: -15px;
}

@media (max-width: 1199px) {
  .ec-news .xb-item--cat {
    left: 20px;
  }
}

.ec-news .xb-item--holder {
  padding-top: 40px;
  padding-left: 30px;
  padding: 40px 30px 30px;
  position: relative;
}

@media (max-width: 1199px) {
  .ec-news .xb-item--holder {
    padding: 40px 20px 30px;
  }
}

.ec-news .xb-item--left {
  padding-right: 30px;
}

.ec-news .xb-item--ad-text {
  font-size: 16px;
  padding-left: 9px;
}

.ec-news .xb-item--title {
  font-family: var(--font-body);
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: #0f172a;
  padding-top: 30px;
}

@media (max-width: 1199px) {
  .ec-news .xb-item--title {
    font-size: 20px;
    line-height: 30px;
  }
}

.ec-news .xb-item--title a {
  color: currentColor;
}

.ec-news:hover .ec-btn .ec-news {
  background: #1140c0;
  border: 1px solid #1140c0;
  color: var(--color-white);
}

.ec-news:hover .ec-btn .ec-news span svg {
  filter: brightness(100);
}

.ec-btn .ec-news {
  border: 1px solid #e1e7e8;
  box-shadow: none;
  padding: 17px 38px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.ec-btn .ec-news span svg {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.news-shape .shape {
  position: absolute;
  z-index: -1;
}

.news-shape .shape--one {
  top: 21%;
  left: 116px;
}

.news-shape .shape--two {
  bottom: 19%;
  right: 101px;
}

.xb-process {
  text-align: center;
  padding-bottom: 44px;
  position: relative;
  z-index: 1;
  height: auto;
  width: 430px;
}

@media (max-width: 991px) {
  .xb-process {
    padding: 0 20px 40px;
    width: auto;
  }
}

.xb-process::after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  width: 100%;
  height: 72%;
  opacity: 0;
  border-radius: 10px 10px 0 0;
  background: #67a859;
  z-index: -1;
  transform: scaleY(0);
  transform-origin: bottom;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.xb-process .xb-item--img {
  border-radius: 50%;
  display: inline-block;
}

.xb-process .xb-item--img .number {
  background: var(--color-white);
  height: 50px;
  width: 50px;
  bottom: 0;
  right: 0;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: var(--color-heading);
  font-size: 26px;
  line-height: 28px;
}

.xb-process .xb-item--title {
  font-family: var(--font-body);
  font-weight: 600;
  font-size: 26px;
  line-height: 28px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.xb-process .xb-item--text {
  max-width: 298px;
  display: inline-block;
  color: var(--color-heading);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.xb-process:hover::after,
.xb-process.active::after {
  opacity: 1;
  transform: scaleY(1);
}

.xb-process:hover .xb-item--title,
.xb-process.active .xb-item--title {
  color: var(--color-white);
}

.xb-process:hover .xb-item--text,
.xb-process.active .xb-item--text {
  color: var(--color-white);
}

.xb-process-wrap {
  justify-content: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .xb-process-wrap {
    flex-wrap: nowrap;
  }
}

@media (max-width: 1199px) {
  .xb-process-wrap {
    flex-wrap: nowrap;
  }
}

@media (max-width: 991px) {
  .xb-process-wrap {
    flex-wrap: wrap;
  }
}

.xb-team {
  overflow: hidden;
  border-radius: 15px;
}

.xb-team img {
  height: 100%;
  width: 100%;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.xb-team .xb-item--holder {
  text-align: center;
  position: absolute;
  bottom: 22px;
  left: 20px;
  right: 20px;
  background: var(--color-white);
  border-radius: 10px;
  padding: 21px 10px 0;
}

.xb-team .xb-item--title {
  font-family: var(--font-body);
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .xb-team .xb-item--title {
    font-size: 18px;
  }
}

@media (max-width: 1199px) {
  .xb-team .xb-item--title {
    font-size: 18px;
  }
}

.xb-team .xb-item--title a {
  color: currentColor;
}

.xb-team .xb-item--text {
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 15px;
}

@media (max-width: 1199px) {
  .xb-team .xb-item--text {
    font-size: 14px;
  }
}

.xb-team .xb-item--social_link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  background: #0077b5;
  position: absolute;
  top: -20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.xb-team .xb-item--social_link i {
  color: var(--color-white);
}

.xb-team:hover img {
  transform: scale(1.1);
}

.ec-team {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  min-height: 400px;
}

.ec-team::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 0;
  height: 401px;
  width: 216px;
  background: var(--xb-track-color);
  border-radius: 116px;
  transform: translateX(-50%);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.ec-team .xb-item--shape {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  height: 100%;
}

.ec-team .xb-item--shape img {
  height: 100%;
}

.ec-team .xb-item--avater {
  position: relative;
  z-index: 2;
  padding-top: 69px;
}

.ec-team .xb-item--img {
  overflow: hidden;
  border-radius: 100%;
}

.ec-team .xb-item--title {
  font-family: var(--font-body);
  font-weight: 600;
  font-size: 22px;
  color: var(--xb-track-color);
  padding: 20px 0 2px;
}

.ec-team .xb-item--title a {
  color: currentColor;
}

.ec-team .xb-item--text {
  font-size: 16px;
  line-height: 24px;
}

.ec-team .xb-item--social-link {
  height: 40px;
  width: 40px;
  background: var(--color-white);
  border-radius: 100%;
  box-shadow: 0 2px 6px 0 rgba(6, 8, 60, 0.55);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  bottom: 18px;
  z-index: 2;
  transform: translateX(-50%);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.ec-team .xb-item--social-link i {
  color: var(--xb-track-color);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.ec-team:hover::before {
  background: #1140C0;
}

.ec-team:hover .xb-item--social-link {
  border: 1px solid #1140c0;
}

.team-shape .shape {
  position: absolute;
}

.team-shape .shape--one {
  top: 52%;
  right: 125px;
}

.team_member_details_card {
  padding: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 100px;
  background-color: var(--color-white);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

@media (max-width: 1199px) {
  .team_member_details_card {
    gap: 30px;
    margin-bottom: 80px;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 30px 40px;
  }
}

.team_member_details_card .team_member_image {
  height: 454px;
  flex: 0 0 490px;
  overflow: hidden;
  margin: 0 100px 0 0;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

@media (max-width: 1199px) {
  .team_member_details_card .team_member_image {
    margin: 0 50px 0 0;
  }
}

@media (max-width: 767px) {
  .team_member_details_card .team_member_image {
    margin: 0;
  }
}

.team_member_details_card .team_member_image img {
  height: 100%;
  object-fit: cover;
}

.team_member_details_card .team_member_name {
  font-size: 35px;
  line-height: 50px;
  margin-bottom: 30px;
  font-family: var(--font-body);
}

.team_member_details_card .icon_list {
  gap: 14px;
  margin-bottom: 40px;
}

.team_member_details_card .icon_list>li {
  font-size: 18px;
  line-height: 30px;
}

.team_member_details_card .icon_list strong {
  color: var(--color-primary);
}

.team_member_details_card .social_title {
  line-height: 1;
  font-size: 18px;
  margin-bottom: 15px;
  font-family: var(--font-body);
}

.unordered_list_block {
  display: flex;
  flex-direction: column;
}

.icon_list>li {
  gap: 10px;
  display: flex;
  font-size: 16px;
  line-height: 28px;
  align-items: flex-start;
}

.unordered_list_block>li {
  width: 100%;
  display: block;
}

.social_icons_block {
  gap: 10px;
}

.social_icons_block a {
  width: 40px;
  height: 40px;
  display: flex;
  flex: 0 0 auto;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  border: 1px solid #E8E4DE;
  color: var(--color-primary);
}

.social_icons_block a:hover {
  background-color: var(--color-yellow);
  border-color: var(--color-yellow);
}

.details_item_info_title {
  font-size: 35px;
  line-height: 50px;
  margin-bottom: 17px;
}

@media (max-width: 767px) {
  .details_item_info_title {
    font-size: 30px;
  }
}

.faq__blockchain {
  margin: 0 133px;
}

@media (max-width: 1199px) {
  .faq__blockchain {
    margin: 0 50px;
  }
}

@media (max-width: 991px) {
  .faq__blockchain {
    margin: 0;
  }
}

.faq__blockchain .accordion_box {
  list-style: none;
}

.faq__blockchain .accordion_box .block {
  background: var(--color-white);
  box-shadow: 0 8px 15px 0 rgba(220, 220, 220, 0.25);
  margin: 20px 0;
  border-radius: 10px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.faq__blockchain .accordion_box .block .acc-btn {
  padding-bottom: 17px;
  font-weight: 600;
  font-size: 22px;
  color: var(--color-primary);
  position: relative;
  padding: 37px 20px;
  cursor: pointer;
  padding-top: 37px;
  padding-left: 48px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 767px) {
  .faq__blockchain .accordion_box .block .acc-btn {
    padding-left: 20px;
    padding-right: 50px;
    font-size: 18px;
  }
}

.faq__blockchain .accordion_box .block .acc_body {
  position: relative;
  display: none;
}

.faq__blockchain .accordion_box .block .acc_body.current {
  display: block;
}

.faq__blockchain .accordion_box .block .content {
  border-top: 0;
  padding-top: 0;
  padding-right: 45px;
  line-height: 28px;
  color: var(--color-primary);
  padding-left: 92px;
  padding-bottom: 35px;
}

@media (max-width: 767px) {
  .faq__blockchain .accordion_box .block .content {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.faq__blockchain .accordion_box .block .qus {
  font-weight: 600;
  font-size: 22px;
  color: rgba(11, 57, 72, 0.5);
  padding-right: 13px;
}

.faq__blockchain .accordion.block.active-block {
  background: var(--color-primary);
}

.faq__blockchain .accordion.block.active-block .content {
  color: var(--color-white);
}

.faq__blockchain .accordion.block.active-block .qus {
  font-weight: 600;
  font-size: 22px;
  color: var(--color-white);
  opacity: 0.5;
}

.faq__blockchain .accordion.block.active-block .acc-btn {
  color: var(--color-white);
  padding-bottom: 22px;
}

.faq__blockchain .accordion.block.active-block .acc-btn .arrow {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  background: var(--color-yellow);
}

.faq__blockchain .accordion.block.active-block .acc-btn .arrow::after {
  opacity: 1;
}

.faq__blockchain .accordion.block.active-block .acc-btn .arrow div::before {
  opacity: 0;
}

.faq__blockchain .accordion.block.active-block .acc-btn .arrow div::after {
  opacity: 1;
}

.faq__blockchain .acc-btn::before,
.faq__blockchain .acc-btn::after {
  display: none;
}

.faq__blockchain .acc-btn.active .arrow::after {
  opacity: 1;
}

.faq__blockchain .acc-btn.active .arrow div::before {
  opacity: 0;
}

.faq__blockchain .acc-btn.active .arrow div::after {
  opacity: 1;
}

.faq__blockchain .acc-btn span {
  margin: 0 !important;
}

.faq__blockchain .arrow {
  position: absolute;
  top: 48px;
  right: 50px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: #f5f3f0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .faq__blockchain .arrow {
    right: 10px;
  }
}

.faq__blockchain .arrow div::before,
.faq__blockchain .arrow div::after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "\f067";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 13px;
  color: var(--color-primary);
}

.faq__blockchain .arrow div::after {
  content: "\f068";
  opacity: 0;
  z-index: 1;
  top: 48%;
}

.xb-protfolio:hover .xb-item--img img {
  -webkit-transform: scale(1.09);
  -ms-transform: scale(1.09);
  transform: scale(1.09);
}

.xb-protfolio .xb-item--img {
  overflow: hidden;
  border-radius: 20px;
}

.xb-protfolio .xb-item--img a {
  width: 100%;
  height: 498px;
  display: inline-block;
}

@media (max-width: 991px) {
  .xb-protfolio .xb-item--img a {
    height: 350px;
  }
}

.xb-protfolio .xb-item--img img {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.xb-protfolio .xb-item--holder {
  padding-top: 27px;
}

.xb-protfolio .xb-item--title {
  font-family: var(--font-body);
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: var(--xb-track-color);
  padding-bottom: 5px;
}

.xb-protfolio .xb-item--title a {
  color: currentColor;
}

.xb-protfolio .xb-item--text {
  font-size: 14px;
}

.xb-protfolio .xb-item--arrow {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-white);
  box-shadow: 0 1px 6px 0 rgba(93, 106, 131, 0.25);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.xb-protfolio:hover .xb-item--arrow {
  background: #1140c0;
}

.xb-protfolio:hover .xb-item--arrow svg {
  filter: brightness(100);
}

.protfolio-shape .shape {
  position: absolute;
  z-index: -1;
}

.protfolio-shape .shape--one {
  top: 308px;
  left: 103px;
}

.protfolio-shape .shape--two {
  bottom: 46%;
  right: 108px;
}

.portfolio-pb {
  padding-bottom: 471px;
}

.xb-protfolio-two:hover .xb-item--arrow {
  background-color: var(--color-primary);
}

.xb-protfolio-two:hover .xb-item--arrow svg {
  filter: unset;
}

.xb-protfolio-two:hover .xb-item--arrow svg path {
  fill: var(--color-yellow);
}

.portfolio_details_info_list {
  row-gap: 6px;
  column-gap: 50px;
  background-color: var(--color-primary);
  padding: 40px 30px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.portfolio_details_info_list li {
  color: #D8E3F8;
  font-size: 18px;
  line-height: 30px;
}

.portfolio_details_info_list li strong {
  font-weight: 500;
  color: var(--color-white);
  margin-right: 10px;
}

.portfolio-single__content h3 {
  font-size: 35px;
  line-height: 50px;
  margin-bottom: 40px;
}

.portfolio-single__content p {
  margin-bottom: 40px;
}

.portfolio-singe__list {
  padding-right: 15%;
}

@media (max-width: 1199px) {
  .portfolio-singe__list {
    padding-right: 0;
  }
}

.portfolio-singe__list li {
  margin-top: 20px;
  width: 50%;
  font-size: 18px;
  font-weight: 500;
}

@media (max-width: 767px) {
  .portfolio-singe__list li {
    width: 100%;
  }
}

.portfolio-singe__list li img {
  margin-right: 6px;
}

.ec-contact_left .xb-item--img {
  padding-top: 5px;
  margin-left: -86px;
  z-index: -2;
  position: relative;
}

@media (max-width: 991px) {
  .ec-contact_left .xb-item--img {
    margin-left: 0;
    margin-bottom: 30px;
  }
}

.ec-contact_left .xb-item--holder {
  -webkit-transform: translateY(-34px);
  -ms-transform: translateY(-34px);
  transform: translateY(-34px);
}

@media (max-width: 991px) {
  .ec-contact_left .xb-item--holder {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
}

.ec-contact_left .xb-item--call-icon {
  background: #1140c0;
  height: 64px;
  width: 64px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ec-contact_left .xb-item--call_info {
  padding-left: 20px;
  transform: translateY(4px);
}

.ec-contact_left .xb-item--call_text {
  font-size: 20px;
}

.ec-contact_left .xb-item--num_text {
  font-weight: 600;
  font-size: 34px;
  color: #06083c;
  padding-top: 6px;
}

.ec-contact_right {
  background: var(--color-white);
  border-radius: 20px;
  padding: 30px 40px;
  margin-left: 80px;
}

@media (max-width: 991px) {
  .ec-contact_right {
    margin-left: 0;
  }
}

.ec-contact_right .xb-item--title {
  font-size: 32px;
  line-height: 64px;
  color: #06083c;
}

.ec-contact_right .ec-btn {
  padding: 17px 0 20px;
}

.ec-contact_right .ec-btn .con-btn {
  background: #1140c0;
  color: var(--color-white);
  text-align: center;
  display: block;
  width: 100%;
}

.ec-contact_right .ec-btn .con-btn svg {
  filter: brightness(100);
}

.ec-contact-form .input-field {
  position: relative;
}

.ec-contact-form .input-field input,
.ec-contact-form .input-field select,
.ec-contact-form .input-field textarea {
  border: 1px solid #e1e7e8;
  border-radius: 10px;
  background: #f5f5f5;
  padding-left: 48px;
  height: 60px;
  margin-bottom: 20px;
}

.ec-contact-form .input-field .xb-icon {
  position: absolute;
  top: 22px;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.ec-contact-form .massage-field textarea {
  height: 171px;
  padding-top: 16px;
}

.contact-shape .shape {
  position: absolute;
  z-index: -1;
}

.contact-shape .shape--1 {
  left: 14%;
  top: 34%;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .contact-shape .shape--1 {
    left: 0;
  }
}

@media (max-width: 1199px) {
  .contact-shape .shape--1 {
    left: 0;
  }
}

.contact-shape .shape--2 {
  left: 31%;
  top: 34%;
}

.contact-shape .shape--three {
  bottom: 43.5%;
  right: 224px;
}

.xb-contact-bg {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.contact_info_box .iconbox_block {
  padding: 60px 30px 52px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  box-shadow: 0 8px 15px 0 rgba(220, 220, 220, 0.25);
}

.contact_info_box .iconbox_block .iconbox_icon img {
  max-width: 24px;
}

.contact_info_box .iconbox_block .iconbox_icon {
  width: 60px;
  height: 60px;
  margin: 0 0 32px;
  background-color: #F7F5F2;
  border: 1px solid #EEE5E5;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.contact_info_box .iconbox_block .iconbox_title {
  line-height: 1;
  font-size: 18px;
  font-weight: 600;
  font-family: var(--font-body);
  margin-bottom: 30px;
}

.contact_method_box {
  padding: 46px 50px 50px;
  background-color: var(--color-white);
  border-radius: 20px;
  box-shadow: 0 4px 23px 0 rgba(174, 191, 210, 0.3);
}

.contact_method_box .contact_method_list {
  margin-bottom: 50px;
}

.contact_method_list {
  gap: 10px;
}

.contact_method_list a {
  gap: 20px;
  align-items: center;
  display: inline-flex;
  color: var(--bs-dark);
}

.contact_method_list a:hover {
  color: var(--color-primary);
}

.contact_method_list .icon {
  width: 40px;
  height: 40px;
  flex: 0 0 auto;
  align-items: center;
  border-radius: 100%;
  display: inline-flex;
  color: var(--color-white);
  justify-content: center;
  background-color: var(--color-primary);
}

.contact_method_list .icon i {
  animation: none;
}

.contact_method_list .text {
  font-size: 17px;
  font-weight: 500;
}

.support_step {
  gap: 10px;
  z-index: 1;
  position: relative;
}

.support_step:before {
  top: 0;
  bottom: 0;
  left: 20px;
  width: 1px;
  content: '';
  z-index: -1;
  position: absolute;
  background-color: var(--color-primary);
}

.support_step>li {
  gap: 10px;
  display: flex;
  align-items: center;
}

.support_step .serial_number {
  width: 40px;
  height: 40px;
  display: flex;
  flex: 0 0 auto;
  padding-top: 4px;
  font-weight: 500;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  color: var(--color-primary);
  background-color: var(--color-white);
  border: 1px solid var(--color-primary);
}

.support_step .text {
  font-size: 17px;
  padding-top: 4px;
  font-weight: 500;
}

.contact_form {
  background-color: var(--color-white);
  padding: 50px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  box-shadow: 0 8px 15px 0 rgba(220, 220, 220, 0.25);
  margin-right: 60px;
}

@media (max-width: 991px) {
  .contact_form {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .contact_form {
    padding: 30px;
  }
}

.contact_form .form-group {
  margin-bottom: 30px;
}

.instant_contact_form {
  padding: 50px;
  background-color: var(--color-white);
  border-radius: 20px;
  box-shadow: 0 4px 23px 0 rgba(174, 191, 210, 0.3);
}

.instant_contact_form .small_title {
  line-height: 1;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 26px;
  color: var(--bs-dark);
  text-transform: uppercase;
}

.instant_contact_form .small_title i {
  color: var(--color-primary);
}

.instant_contact_form .form_title {
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 19px;
}

.instant_contact_form .form-group .form-control,
.instant_contact_form .form-group .form-select {
  box-shadow: none;
  padding-left: 50px;
  caret-color: var(--bs-dark);
}

.instant_contact_form .form-group .form-control:focus,
.instant_contact_form .form-group .form-select:focus {
  background-color: var(--bs-light);
  border-color: rgba(0, 68, 235, 0.1);
}

.instant_contact_form .form-group .input_title {
  top: 22px;
  left: 22px;
  position: absolute;
}

.instant_contact_form .form-group .input_title i {
  color: #676767;
  animation: none;
}

.maps-ndis {
  margin-left: -45px;
}

@media (max-width: 991px) {
  .maps-ndis {
    margin-left: 0;
  }
}

.maps-ndis iframe {
  width: 100%;
  height: 721px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

@media (max-width: 991px) {
  .maps-ndis iframe {
    height: 400px;
  }
}

.form-group {
  position: relative;
}

.form-group:not(:last-child) {
  margin-bottom: 30px;
}

.form-group .icon {
  position: absolute;
  bottom: 7px;
  top: 48px;
  left: 20px;
}

.form-group .form-control,
.form-group .form-select {
  padding: 0 24px;
  padding-left: 46px;
  font-size: 16px;
  transition: 0.3s ease;
  background-color: #F7F5F2;
  border: 1px solid #EEE5E5;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.form-group .form-control:focus,
.form-group .form-select:focus {
  box-shadow: none;
}

.form-group .form-control::placeholder,
.form-group .form-select::placeholder {
  color: #8F959F;
  font-size: 16px;
  transform: translateY(3px);
}

.form-group .form-control:-ms-input-placeholder,
.form-group .form-select:-ms-input-placeholder {
  color: #8F959F;
  font-size: 16px;
  transform: translateY(3px);
}

.form-group .form-control::-ms-input-placeholder,
.form-group .form-select::-ms-input-placeholder {
  color: #8F959F;
  font-size: 16px;
  transform: translateY(3px);
}

.form-group input.form-control,
.form-group select.form-select {
  height: 60px;
}

.form-group textarea.form-control {
  min-height: 137px;
  padding: 15px 25px 25px 46px;
}

.form-group .form-select {
  color: #8C8F92;
}

.input_title {
  display: block;
  line-height: 1;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 17px;
  font-family: var(--font-body);
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

/*----------------------------------------*/
/*  03. globel
/*----------------------------------------*/
@media (min-width: 1024px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1320px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.home-two {
  background-color: var(--color-white);
}

.home-two main {
  background-color: var(--color-white);
}

.home-three {
  background-color: #f5f5f5;
  color: var(--color-default-two);
}

.home-three main {
  background-color: #f5f5f5;
}

.mxw_1660 {
  max-width: 1660px;
}

.mxw_1820 {
  max-width: 1820px;
}

.px-60 {
  padding: 0 60px;
}

@media (max-width: 991px) {
  .container.px-60 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.g-15 {
  padding: 0 5px;
}

.g-15>* {
  padding-left: 8px;
  padding-right: 8px;
}

.tagcloud,
.tags-links {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  margin: -10px;
}

.tagcloud a,
.tags-links a {
  display: block;
  color: #5D6A83;
  border: 1px solid #EEE5E5;
  background-color: var(--color-white);
  min-height: 36px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
  padding: 4px 17px;
  margin: 7px;
  position: relative;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.tagcloud a:hover,
.tags-links a:hover {
  color: var(--color-white);
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.body-overlay {
  background-color: #000000;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1010;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear 0s;
  transition: all 600ms ease;
  -webkit-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
}

.tags-links a {
  min-height: 32px;
  padding: 2px 17px;
}

.body-overlay.active {
  opacity: .5;
  visibility: visible;
}

.demo-senior-care .body_wrap {
  background-color: var(--color-white);
  padding-left: 80px;
  padding-right: 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .demo-senior-care .body_wrap {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 1199px) {
  .demo-senior-care .body_wrap {
    padding: 0;
  }
}

.post-tags li {
  font-size: 15px;
  position: relative;
}

.post-tags li:not(:first-child, :last-child) {
  margin-right: 11px;
  padding-right: 15px;
}

.post-tags li:not(:first-child, :last-child)::before {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  background-color: var(--color-primary);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  content: "";
}

.post-tags li span {
  display: inline-block;
  background-color: var(--color-primary);
  padding: 0px 10px;
  line-height: 25px;
  color: var(--color-white);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  margin-right: 12px;
}

.post-tags li a {
  color: var(--color-black);
}

.post-tags li a:hover {
  color: var(--color-black);
}

.mfp-zoom-in .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

.mfp-zoom-in.mfp-ready .mfp-content {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

body .mfp-wrap .mfp-container .mfp-content .mfp-close {
  padding: 0;
  right: 0;
  text-align: center;
  top: -36px;
  width: 36px;
  height: 36px;
  -webkit-border-radius: 0;
  -khtml-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  background: var(--color-white);
  cursor: pointer;
  opacity: 1;
  font-size: 0;
  border: 9px solid transparent;
  position: absolute;
}

body .mfp-wrap .mfp-container .mfp-content .mfp-figure .mfp-close {
  top: 4px;
}

body .mfp-wrap .mfp-container .mfp-content .mfp-close::before,
body .mfp-wrap .mfp-container .mfp-content .mfp-close::after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  transform-origin: 50% 50%;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -khtml-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -ms-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: #222;
}

body .mfp-wrap .mfp-container .mfp-content .mfp-close::before {
  -webkit-transform: rotate(45deg);
  -khtml-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

body .mfp-wrap .mfp-container .mfp-content .mfp-close::after {
  -webkit-transform: rotate(-45deg);
  -khtml-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

body .mfp-wrap .mfp-container .mfp-content .mfp-close:hover::before,
body .mfp-wrap .mfp-container .mfp-content .mfp-close:hover::after {
  -webkit-transform: rotate(0);
  -khtml-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
}

body .mfp-wrap .mfp-container .mfp-content .mfp-close:hover::before,
body .mfp-wrap .mfp-container .mfp-content .mfp-close:hover::after {
  -webkit-transform: rotate(0);
  -khtml-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
}

.mfp-iframe-holder .mfp-content {
  max-width: 1170px;
}

.xb-carousel-inner {
  margin: -30px;
}

.xb-swiper-sliders {
  position: relative;
}

.xb-swiper-container {
  overflow: hidden;
  padding: 30px;
}

.xb-swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.xb-swiper-slide:not(.swiper-slide-visible) {
  opacity: 0;
}

.xb-swiper-slide {
  -webkit-transition: opacity 0.25s cubic-bezier(0.71, 0.02, 0.31, 1);
  -khtml-transition: opacity 0.25s cubic-bezier(0.71, 0.02, 0.31, 1);
  -moz-transition: opacity 0.25s cubic-bezier(0.71, 0.02, 0.31, 1);
  -ms-transition: opacity 0.25s cubic-bezier(0.71, 0.02, 0.31, 1);
  -o-transition: opacity 0.25s cubic-bezier(0.71, 0.02, 0.31, 1);
  transition: opacity 0.25s cubic-bezier(0.71, 0.02, 0.31, 1);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

.z-index-2 {
  z-index: 2;
  position: relative;
}

.xb-overlay {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.xb-overlay-link {
  z-index: 1;
}

.text-12 {
  font-size: 12px;
}

.letter-spacing-0 {
  letter-spacing: 0;
}

@media (max-width: 991px) {
  .margin-none-md {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .mb-30-xs {
    margin-bottom: 30px;
  }
}

.br-20 {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.z-1 {
  z-index: 1;
}

.z-3 {
  position: relative;
  z-index: 3;
}

.ml-auto {
  margin-left: auto;
}

.align-end {
  align-items: flex-end;
}

.xb-hover-zoom:hover .xb-item--img img {
  -webkit-transform: scale(1.08);
  -ms-transform: scale(1.08);
  transform: scale(1.08);
}

.xb-hover-zoom .xb-item--img {
  overflow: hidden;
}

.xb-hover-zoom .xb-item--img img {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.xb-law-swiper-arrow .xb-swiper-arrow {
  border: 1px solid rgba(255, 255, 255, 0.12);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: var(--color-white);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.xb-law-swiper-arrow .xb-swiper-arrow.xb-swiper-arrow-next {
  margin-left: 10px;
}

.xb-law-swiper-arrow .xb-swiper-arrow:hover,
.xb-law-swiper-arrow .xb-swiper-arrow.xb-swiper-arrow-next {
  color: var(--color-law);
  background-color: var(--color-white);
}

.xb-law-swiper-arrow-main-color .xb-swiper-arrow {
  background-color: var(--color-law);
  color: var(--color-white);
}

.pagination-style1 {
  bottom: -60px;
}

.pagination-style1 .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  background-color: #162432;
}

.pagination-style1.style-white .swiper-pagination-bullet {
  background-color: var(--color-white);
}

.text-heading {
  color: var(--color-heading);
}

.text-24 {
  font-size: 24px;
}

.weight-medium {
  font-weight: 500;
}

.xb-blur {
  background: var(--color-primary);
  filter: blur(139px);
  height: 481px;
  width: 481px;
  border-radius: 50%;
  z-index: -2;
}

@media (max-width: 991px) {
  .xb-blur {
    height: 300px;
    width: 300px;
  }
}

@media (max-width: 767px) {
  .xb-blur {
    height: 150px;
    width: 150px;
  }
}

.social-icon li {
  margin-top: 10px;
}

.social-icon li:not(:last-child) {
  margin-right: 31px;
}

@media (max-width: 991px) {
  .social-icon li:not(:last-child) {
    margin-right: 20px;
  }
}

.social-icon li a {
  color: #FFFFFF;
  font-size: 24px;
  height: 64px;
  width: 64px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media (max-width: 767px) {
  .social-icon li a {
    font-size: 18px;
    height: 55px;
    width: 54px;
  }
}

.social-icon li a::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: '';
  z-index: -1;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-transition: all 0.4s cubic-bezier(0.56, 0.26, 0.31, 1.32);
  -o-transition: all 0.4s cubic-bezier(0.56, 0.26, 0.31, 1.32);
  transition: all 0.4s cubic-bezier(0.56, 0.26, 0.31, 1.32);
  transition-delay: .1s;
  transform-style: preserve-3d;
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  transform-origin: center;
  border: 1px solid rgba(255, 255, 255, 0.15);
}

.social-icon li a::after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: '';
  z-index: -1;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-transition: all 0.4s cubic-bezier(0.56, 0.26, 0.31, 1.32);
  -o-transition: all 0.4s cubic-bezier(0.56, 0.26, 0.31, 1.32);
  transition: all 0.4s cubic-bezier(0.56, 0.26, 0.31, 1.32);
  background-color: var(--color-primary);
  transition-delay: .1s;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  transform-style: preserve-3d;
  transform-origin: center;
}

.social-icon li a:hover {
  color: #020202;
}

.social-icon li a:hover::before {
  transform: scale(0);
}

.social-icon li a:hover::after {
  transform: scaleX(1);
}

.row.g-150 {
  margin-left: -75px;
  margin-right: -75px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .row.g-150 {
    margin-left: -35px;
    margin-right: -35px;
  }
}

@media (max-width: 1199px) {
  .row.g-150 {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.row.g-150>* {
  padding-left: 75px;
  padding-right: 75px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .row.g-150>* {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media (max-width: 1199px) {
  .row.g-150>* {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.line_wrap {
  top: 0px;
  bottom: 0px;
  width: 100%;
  display: block;
  position: absolute;
  max-width: 1292px;
  margin: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .line_wrap {
    max-width: 1171px;
  }
}

@media (max-width: 1199px) {
  .line_wrap {
    display: none;
  }
}

.line_wrap .xb-line::before,
.line_wrap .xb-line::after {
  position: absolute;
  top: 0;
  left: -1px;
  width: 1px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.18);
  content: "";
  z-index: 2;
}

.line_wrap .xb-line::after {
  left: auto;
  right: 1px;
}

.xb-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.xb-svg svg {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: visible;
}

.hero-style-one {
  min-height: 856px;
}

@media (max-width: 1199px) {
  .hero-style-one {
    padding-bottom: 110px;
    min-height: 800px;
  }
}

@media (max-width: 991px) {
  .hero-style-one {
    padding-bottom: 0;
  }
}

@media (max-width: 1199px) {
  .hero-style-one .hero__content-wrap {
    padding-top: 110px;
  }
}

.hero-right_img {
  margin-right: -235px;
}

@media (max-width: 1199px) {
  .hero-right_img {
    margin-right: -100px;
    margin-top: 100px;
  }
}

@media (max-width: 991px) {
  .hero-right_img {
    margin-right: 0;
    margin-top: 0;
  }
}

.hero-shape {
  position: absolute;
  top: 51px;
  right: 214px;
  z-index: -1;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .hero-shape {
    top: 20px;
    right: -50px;
  }
}

@media (max-width: 1199px) {
  .hero-shape {
    right: -30%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .hero-shape {
    max-width: 700px;
  }
}

.hero-right-two {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .hero-right-two {
    right: -14%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .hero-right-two {
    right: -24%;
  }
}

@media (max-width: 1199px) {
  .hero-right-two {
    right: -38%;
    max-width: 93%;
    top: 0;
    height: 100%;
  }

  .hero-right-two img {
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 991px) {
  .hero-right-two {
    position: unset;
    max-width: 100%;
  }
}

.section-title .clr-white {
  color: var(--color-white);
}

.xb-review-wrap .xb-item--text {
  font-size: 14px;
  display: inline-block;
  padding-bottom: 2px;
}

.xb-review-wrap .xb-item--inner {
  padding-right: 10.35px;
}

.xb-review-wrap .xb-item--img {
  margin-left: -4px;
}

@media (max-width: 1199px) {
  .xb-review-wrap .xb-item--img img {
    height: 30px;
  }
}

.xb-review-wrap .xb-item--star {
  padding-bottom: 10px;
}

.xb-review-wrap .xb-item--star i {
  color: #1140c0;
}

.xb-review-wrap .xb-item--reating {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.xb-review-wrap .xb-item--rating_num {
  font-size: 15px;
  display: inline-block;
}

.hero-right-three {
  position: absolute;
  right: 0;
  top: 72px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .hero-right-three {
    max-width: 600px;
  }
}

@media (max-width: 1199px) {
  .hero-right-three {
    max-width: 450px;
  }
}

@media (max-width: 991px) {
  .hero-right-three {
    margin-top: 40px;
    position: unset;
  }
}

.hero-style-three {
  min-height: 828px;
  background-position: bottom center;
  overflow: hidden;
}

@media (max-width: 767px) {
  .hero-style-three .hero__content-wrap {
    padding-top: 100px;
  }
}

.ec-shape .shape {
  position: absolute;
  z-index: -1;
}

@media (max-width: 767px) {
  .ec-shape .shape {
    display: none;
  }
}

.ec-shape .shape--one {
  top: 129px;
  left: 76px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .ec-shape .shape--one {
    top: 50px;
  }
}

@media (max-width: 1199px) {
  .ec-shape .shape--one {
    top: 70px;
    left: 40px;
  }
}

.ec-shape .shape--two {
  top: 68px;
  right: 46%;
}

.ec-shape .shape--three {
  top: 201px;
  right: 49px;
}

.ec-shape .shape--four {
  left: 98px;
  bottom: 37%;
}

.ec-shape .shape--five {
  top: 178px;
  right: 33.5%;
}

.ec-shape .shape--six {
  left: 34%;
  bottom: 70px;
}

.hero-style-four {
  min-height: 779px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

@media (max-width: 1199px) {
  .hero-style-four {
    min-height: 600px;
  }
}

@media (max-width: 991px) {
  .hero-style-four {
    padding: 100px 0;
  }
}

.sc-hero-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
}

.sc-hero-form input {
  border: 1px solid rgba(101, 101, 101, 0.5);
  height: 60px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  font-size: 16px;
  background-color: transparent;
  max-width: 340px;
}

.sc-hero-form input::-webkit-input-placeholder {
  color: #656565;
  opacity: 1;
}

.sc-hero-form input::-moz-placeholder {
  color: #656565;
  opacity: 1;
}

.sc-hero-form input:-ms-input-placeholder {
  color: #656565;
  opacity: 1;
}

.sc-hero-form input:-moz-placeholder {
  color: #656565;
  opacity: 1;
}

@media (max-width: 1199px) {
  .sc-hero-form input {
    max-width: 270px;
  }
}

.sc-hero-form button {
  padding: 17px 30px;
  color: var(--color-white);
  height: 60px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.sc-hero-form button:hover {
  transform: translate(0);
}

.sc-hero-content {
  background-color: var(--color-white);
  padding: 90px;
  padding-top: 80px;
  margin-left: -90px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  margin-right: 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .sc-hero-content {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 1199px) {
  .sc-hero-content {
    margin: 0;
    padding: 40px 30px;
  }
}

.sc-hero-img {
  margin-right: -148px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .sc-hero-img {
    margin-right: -100px;
  }
}

@media (max-width: 1199px) {
  .sc-hero-img {
    margin-right: -50px;
  }
}

@media (max-width: 991px) {
  .sc-hero-img {
    margin-right: 0;
    margin-top: 30px;
  }
}

.hero-right-dot .dot {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: var(--color-primary);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.hero-right-dot .dot--1 {
  top: 19%;
  left: 25%;
  animation: animationFramesOne 15s alternate infinite linear;
  -webkit-animation: animationFramesOne 15s alternate infinite linear;
}

.hero-right-dot .dot--2 {
  background-color: var(--color-yellow);
  bottom: 25%;
  right: 24%;
  animation: animationFramesFour 15s alternate infinite linear;
  -webkit-animation: animationFramesFour 15s alternate infinite linear;
}

.xb-fanfact .xb-item--icon {
  margin-right: 15px;
}

.xb-fanfact .xb-item--fan_inner:not(:last-child) {
  margin-right: 79px;
}

.xb-fanfact .xb-item--number {
  font-size: 50px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: -0.03px;
}

.xb-fanfact .xb-item--text {
}

.xb-fanfact--two {
  overflow: hidden;
  background: #ededed;
  max-width: 707px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .xb-fanfact--two {
    background-color: transparent;
    max-width: auto;
  }
}

@media (max-width: 1199px) {
  .xb-fanfact--two {
    background-color: transparent;
    max-width: auto;
  }
}

.xb-fanfact--two .xb-item--fan_inner {
  padding: 41px 0;
  width: 353px;
  background: var(--color-white);
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .xb-fanfact--two .xb-item--fan_inner {
    width: auto;
  }
}

@media (max-width: 1199px) {
  .xb-fanfact--two .xb-item--fan_inner {
    width: auto;
  }
}

@media (max-width: 991px) {
  .xb-fanfact--two .xb-item--fan_inner {
    padding: 20px 0;
  }
}

.xb-fanfact--two .xb-item--fan_inner:not(:last-child) {
  margin-right: 0;
}

.xb-fanfact--two .xb-item--fan_inner:nth-child(1) {
  border-radius: 0 0 20px 0;
  margin-right: 1px;
}

.xb-fanfact--two .xb-item--fan_inner:nth-child(2) {
  border-radius: 0 0 0 20px;
}

.xb-fanfact--two .xb-item--fan_inner:nth-child(3) {
  border-radius: 0 20px 0 0;
  margin-top: 1px;
  margin-right: 1px;
}

.xb-fanfact--two .xb-item--fan_inner:nth-child(4) {
  border-radius: 20px 0 0 0;
  margin-top: 1px;
}

.xb-fanfact--two .xb-item--text {
  color: var(--color-heading);
}

.about-left_inner .section-title .title {
  z-index: 1;
  position: relative;
}

.about-left_inner .section-title .title span {
  z-index: unset;
}

.funfact_block {
  position: relative;
  padding: 40px 40px 30px;
  background-color: var(--bs-white);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  box-shadow: 0 4px 23px 0 rgba(174, 191, 210, 0.3);
}

.funfact_block .funfact_icon {
  height: 80px;
  margin-bottom: 59px;
}

.funfact_block .funfact_icon img {
  max-height: 80px;
}

.funfact_block .counter_value {
  line-height: 1;
  font-size: 45px;
  font-weight: 700;
  align-items: center;
  margin-bottom: 10px;
  display: inline-flex;
  color: var(--bs-dark);
}

.funfact_block .funfact_title {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #5D6A83;
  font-family: var(--font-body);
}

.funfact_block .bottom_line {
  bottom: 0;
  left: 50%;
  width: 55%;
  height: 4px;
  position: absolute;
  transform: translateX(-50%);
}

.funfact_block:has(.bottom_line) {
  box-shadow: none;
  padding: 75px 40px 65px;
  border: 1px solid #E8E4DE;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

@media (max-width: 1199px) {
  .funfact_block:has(.bottom_line) {
    padding: 60px 30px;
  }
}

.funfact_block:has(.bottom_line) .counter_value {
  font-size: 65px;
  margin-bottom: 20px;
}

@media (max-width: 1199px) {
  .funfact_block:has(.bottom_line) .counter_value {
    font-size: 50px;
  }
}

.funfact_block:has(.bottom_line) .funfact_title {
  font-size: 18px;
  font-weight: 600;
}

.sc-funfact {
  padding: 40px;
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  background-color: var(--color-white);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.09);
}

.sc-funfact-item {
  background-color: #ECF7F9;
  padding: 33px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  text-align: center;
}

.sc-funfact-item .xb-item--number {
  font-size: 55px;
  margin-bottom: 5px;
  color: #006676;
}

@media (max-width: 767px) {
  .sc-funfact-item .xb-item--number {
    font-size: 45px;
  }
}

.sc-funfact-item p {
  color: #656565;
  font-size: 20px;
}

.sc-funfact-item:not(:last-child) {
  margin-bottom: 30px;
}

.sc-funfact-item:nth-child(2) {
  background-color: #F4F0F4;
}

.sc-funfact-item:nth-child(2) .xb-item--number {
  color: #5F2656;
}

.sc-funfact-item:nth-child(3) {
  background-color: #E9F4E7;
}

.sc-funfact-item:nth-child(3) .xb-item--number {
  color: #67A859;
}

.values-item .xb-item--holder {
  background-color: var(--color-white);
  padding: 40px 70px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  position: absolute;
  bottom: 70px;
  left: 0;
  right: 0;
  margin: 0 65px;
  text-align: center;
}

@media (max-width: 767px) {
  .values-item .xb-item--holder {
    position: unset;
    margin: 0;
    padding: 30px;
    border-radius: 0;
  }
}

.values-item .xb-item--holder p {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.02em;
}

.values-item .xb-item--title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  font-family: var(--font-body);
}

.values-item__left {
  border-radius: 20px 0 0 20px;
  -webkit-border-radius: 20px 0 0 20px;
  -moz-border-radius: 20px 0 0 20px;
  -ms-border-radius: 20px 0 0 20px;
  -o-border-radius: 20px 0 0 20px;
  overflow: hidden;
}

.values-item__right {
  border-radius: 0 20px 20px 0;
  -webkit-border-radius: 0 20px 20px 0;
  -moz-border-radius: 0 20px 20px 0;
  -ms-border-radius: 0 20px 20px 0;
  -o-border-radius: 0 20px 20px 0;
  overflow: hidden;
}

@media (max-width: 1199px) {
  .values-item {
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }
}

.mlr--70 {
  margin-left: -70px;
  margin-right: -70px;
}

@media (max-width: 1199px) {
  .mlr--70 {
    margin-left: 0;
    margin-right: 0;
  }
}

.values-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: calc(100% - 50px);
  z-index: -1;
}

.xb-career {
  background-color: var(--color-white);
  padding: 50px;
  padding-top: 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

@media (max-width: 767px) {
  .xb-career {
    padding: 30px;
    padding-top: 0;
  }
}

.xb-career .xb-item--holder p span {
  color: var(--color-primary);
}

.xb-career .xb-item--holder p:not(:last-child) {
  margin-bottom: 20px;
}

.xb-career .xb-item--title {
  font-size: 26px;
  margin-bottom: 10px;
  font-family: var(--font-body);
  margin-bottom: 17px;
}

@media (max-width: 767px) {
  .xb-career .xb-item--title {
    font-size: 22px;
  }
}

.xb-career .xb-item--time {
  margin-right: 10px;
  margin-bottom: 70px;
  display: inline-block;
}

@media (max-width: 1199px) {
  .xb-career .xb-item--time {
    margin-bottom: 30px;
  }
}

.xb-career .xb-item--time i {
  margin-right: 10px;
}

.career-single {
  font-weight: 500;
}

.career-single__content h2 {
  font-size: 35px;
  margin-bottom: 32px;
}

@media (max-width: 767px) {
  .career-single__content h2 {
    font-size: 28px;
  }
}

.career-single__content h3 {
  font-size: 30px;
  margin-bottom: 32px;
}

@media (max-width: 767px) {
  .career-single__content h3 {
    font-size: 26px;
  }
}

.career-single__content p:not(:last-child) {
  margin-bottom: 28px;
}

.career-single__content ul {
  padding-left: 20px;
}

.career-single__content ul li:not(:last-child) {
  margin-bottom: 10px;
}

.career-single__list {
  list-style: none;
  padding-left: 0 !important;
}

.career-single__list li:not(:last-child) {
  margin-bottom: 13px;
}

.career-single__list li span {
  color: #191B1E;
}

.job-apply {
  padding: 80px;
  padding-top: 70px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  background-color: var(--color-white);
}

@media (max-width: 991px) {
  .job-apply {
    padding: 40px 30px;
  }
}

.job-apply__holder h3 {
  font-size: 30px;
  margin-bottom: 15px;
}

.job-apply__holder p {
  margin-bottom: 30px;
}

.job-apply__form label {
  font-size: 20px;
  font-weight: 600;
  color: #191B1E;
  margin-bottom: 10px;
}

.job-apply__form input,
.job-apply__form textarea {
  height: 60px;
  background-color: #F7F5F2;
  font-size: 16px;
  border: 2px solid #EEE5E5;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.job-apply__form input:focus,
.job-apply__form textarea:focus {
  border-color: var(--color-primary);
}

.job-apply__form textarea {
  min-height: 154px;
}

.job-apply__form .xb-field {
  margin-bottom: 33px;
}

.job-apply__form input[type="file"] {
  border: 0;
  padding: 0;
  height: auto;
  cursor: pointer;
  background: transparent;
}

.job-apply__form .xb-btn {
  background-color: var(--color-primary);
}

.job-apply__form .xb-btn:hover {
  background-color: #191B1E;
}

.input-filed-text span {
  color: #191B1E;
}

.sc-career-content {
  background-color: #FCF7F1;
  padding: 80px 40px;
  border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;
  height: 100%;
}

.sc-career-content h2 {
  font-size: 45px;
  margin-bottom: 33px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .sc-career-content h2 {
    font-size: 32px;
    letter-spacing: 0;
  }
}

.sc-career-content p {
  font-size: 18px;
  line-height: 36px;
}

.sc-career-btn a {
  padding: 24px 75px;
}

.breadcrumb {
  padding-top: 100px;
  padding-bottom: 100px;
  min-height: 450px;
  background-position: 90% center;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .breadcrumb {
    min-height: 370px;
  }
}

@media (max-width: 1199px) {
  .breadcrumb {
    min-height: 330px;
    background-position: 75% center;
  }
}

@media (max-width: 767px) {
  .breadcrumb {
    padding-top: 80px;
    padding-bottom: 80px;
    background-position: 45% center;
    min-height: 260px;
  }
}

.breadcrumb__title {
  font-size: 54px;
  line-height: 65px;
  letter-spacing: -0.02em;
  margin-bottom: 15px;
  color: var(--color-white);
}

@media (max-width: 767px) {
  .breadcrumb__title {
    font-size: 34px;
    line-height: 45px;
    letter-spacing: -0.44px;
    margin-bottom: 5px;
  }
}

.breadcrumb__list {
  display: inline-block;
}

.breadcrumb__list li {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.2px;
  display: inline-block;
  color: var(--color-white);
  position: relative;
}

.breadcrumb__list li+.breadcrumb-item::before {
  content: "|";
  color: #fff;
}

@media (max-width: 767px) {
  .breadcrumb__list li {
    font-size: 16px;
  }
}

.breadcrumb__list li a {
  color: rgba(221, 221, 221, 0.5);
}

.thm-btn {
  font-size: 18px;
  color: var(--color-primary);
  background: var(--color-yellow);
  border-style: none;
  padding: 22px 38px;
  align-self: center;
  position: relative;
  line-height: 12px;
  transition: all 0.2s cubic-bezier(0.68, 0.01, 0.58, 0.75);
  display: inline-flex;
  align-items: center;
  letter-spacing: -0.02px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  z-index: 1;
}

.thm-btn:hover {
  background-color: var(--color-primary);
  -webkit-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  transform: translateY(-2px);
  color: var(--color-white);
}

.thm-btn:hover svg {
  -webkit-transform: translateX(4px);
  -ms-transform: translateX(4px);
  transform: translateX(4px);
}

.thm-btn:hover svg path {
  fill: var(--color-white);
}

.thm-btn.hover-white:hover {
  background-color: var(--color-white);
  color: var(--color-primary);
}

.thm-btn.hover-white:hover svg path {
  fill: var(--color-primary);
}

.thm-btn.hover-yellow:hover {
  background-color: var(--color-yellow);
  color: var(--color-primary);
}

.thm-btn.hover-yellow:hover svg path {
  fill: var(--color-primary);
}

.thm-btn svg {
  margin-left: 10px;
  transition: all 0.3s cubic-bezier(0.68, 0.01, 0.58, 0.75);
}

.thm-btn svg path {
  transition: all 0.2s cubic-bezier(0.68, 0.01, 0.58, 0.75);
}

.thm-btn.donate-btn {
  background: #0b3948;
  color: var(--color-white);
  padding: 17px 41px;
}

.thm-btn.donate-btn svg {
  margin-right: 8px;
}

.thm-btn.donate-btn svg path {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.thm-btn.donate-btn:hover svg {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.thm-btn.donate-btn:hover svg path {
  fill: var(--color-primary);
}

.thm-btn--two {
  font-weight: 500;
  border-radius: 60px;
}

.thm-btn--three {
  font-weight: 500;
  border-radius: 60px;
  background: #1140c0;
  color: var(--color-white);
}

.thm-btn--three:hover {
  background-color: var(--color-white);
  color: var(--xb-track-color);
}

.thm-btn--three:hover svg path {
  fill: var(--xb-track-color);
}

.thm-btn--three svg path {
  fill: var(--color-white);
}

.thm-btn.btn-main {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.thm-btn.btn-main svg path {
  fill: var(--color-white);
}

.thm-btn.btn-main:hover {
  background-color: var(--color-yellow);
  color: var(--color-primary);
}

.thm-btn.btn-main:hover svg path {
  fill: var(--color-primary);
}

.thm-btn--three_btn {
  font-weight: 500;
  border-radius: 60px;
  background: var(--color-white);
}

.thm-btn--three_btn:hover {
  background-color: #1140c0;
}

.thm-btn--sc {
  color: var(--color-white);
  background-color: #006676;
  padding: 24px 30px;
  letter-spacing: -0.02em;
}

.thm-btn--sc:hover {
  background-color: #5F2656;
}

.btns {
  margin: -10px;
}

.btns a {
  margin: 10px;
}

/*----------------------------------------*/
/*  27. backtotop
/*----------------------------------------*/
.xb-backtotop {
  right: 30px;
  z-index: 999;
  bottom: 20px;
  position: fixed;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}

.xb-backtotop.active {
  bottom: 40px;
  opacity: 1;
  visibility: visible;
}

.xb-backtotop.style-2 .scroll {
  background-color: #1140C0;
}

.xb-backtotop .scroll {
  z-index: 1;
  width: 40px;
  height: 40px;
  display: block;
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: var(--color-white);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  font-size: 14px;
}

.xb-backtotop .scroll:hover {
  margin-bottom: 4px;
}

.xb-backtotop.style-ins .scroll {
  background: var(--color-ins);
  color: #052328;
}

.xb-backtotop.style-law .scroll {
  background: var(--color-law);
}

.xb-backtotop.style-advisor .scroll {
  background: var(--color-advisor);
}

.xb-backtotop.style-marketing .scroll {
  background: var(--color-marketing);
}

.xb-backtotop.style-business .scroll {
  background: var(--color-business);
  color: #03080B;
}

/*----------------------------------------*/
/*  19. mobile-menu
/*----------------------------------------*/
.xb-hamburger-menu {
  display: none;
}

@media (max-width: 991px) {
  .xb-hamburger-menu {
    display: block;
  }
}

.xb-nav-mobile-button {
  position: relative;
  cursor: pointer;
  font-size: 23px;
  color: var(--color-dark);
}

.xb-header-menu-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  -khtml-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  -ms-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  visibility: hidden;
}

.xb-header-menu {
  position: fixed;
  height: 100vh;
  width: 300px;
  top: 0;
  left: 0;
  bottom: 0;
  margin: 0;
  z-index: 1010;
  overflow: hidden;
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  -khtml-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  -ms-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transform: translateX(-100%);
  -khtml-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  visibility: hidden;
  background-color: #fff;
}

.xb-header-menu.active {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateX(0%);
  -khtml-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
}

.xb-header-menu.active+.xb-header-menu-backdrop {
  opacity: 1;
  visibility: visible;
}

.xb-header-menu-scroll {
  padding: 50px 25px 40px;
}

.xb-header-menu-scroll .xb-close {
  position: absolute;
  top: 0;
  right: 0;
}

.xb-header-menu-scroll .xb-menu-primary>li {
  padding-left: 0;
  padding-right: 0;
}

.xb-header-menu-scroll .xb-menu-primary>li>a .left-icon {
  margin-right: 4px;
}

.xb-header-menu-scroll .xb-menu-primary>li .elementor-section {
  max-width: 100%;
}

.xb-header-menu-scroll .xb-menu-primary>li .elementor-container {
  margin: 10px 0 !important;
}

.xb-header-menu-scroll .xb-menu-primary>li .sub-menu,
.xb-header-menu-scroll .xb-menu-primary>li .children {
  padding-left: 15px;
}

.xb-header-menu-scroll .xb-menu-primary>li .sub-menu a,
.xb-header-menu-scroll .xb-menu-primary>li .children a {
  padding-left: 0;
}

.xb-header-menu-scroll .xb-menu-primary>li .sub-menu.xb-mega-menu,
.xb-header-menu-scroll .xb-menu-primary>li .children.xb-mega-menu {
  padding-left: 0;
}

.xb-menu-toggle {
  position: absolute;
  top: 10px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  right: 0;
  width: 28px;
  cursor: pointer;
  font-size: 15px;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -khtml-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -ms-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #1b1b1b;
  -webkit-border-radius: 3px;
  -khtml-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.03);
  display: inline-block;
}

.xb-menu-toggle:before {
  content: "\f107";
  font-weight: 400;
  font-family: "Font Awesome 5 Pro";
}

.xb-menu-toggle.active:before {
  content: "\f106";
}

.xb-menu-primary {
  list-style: none;
  margin: 0;
  padding: 0;
}

.xb-menu-primary li {
  position: relative;
}

.xb-menu-primary li a {
  display: block;
  line-height: 46px;
  color: var(--color-dark);
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.xb-menu-primary li>a:hover,
.xb-menu-primary li>a.current,
.xb-menu-primary li.current_page_item>a,
.xb-menu-primary li.current-menu-item>a,
.xb-menu-primary li.current_page_ancestor>a,
.xb-menu-primary li.current-menu-ancestor>a {
  color: var(--color-primary);
}

.xb-menu-primary .sub-menu,
.xb-menu-primary .children {
  position: relative;
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

.xb-menu-primary .sub-menu li a,
.xb-menu-primary .children li a {
  font-weight: 500;
  padding-left: 14px;
}

.xb-menu-primary>li>.sub-menu>li>.sub-menu>li a {
  padding-left: 0px;
}

.xb-menu-primary .xb-megamenu .elementor-container>.elementor-column {
  width: 100%;
}

.xb-menu-primary .xb-megamenu .elementor-container .elementor-widget-wrap {
  padding: 0 !important;
}

.xb-menu-primary .xb-megamenu .xb-link li {
  margin: 0;
}

.xb-menu-primary .xb-megamenu .xb-heading {
  padding-left: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding-top: 12px;
  padding-bottom: 12px;
}

.xb-menu-primary .xb-megamenu .xb-heading .xb-item--title {
  margin-bottom: 0 !important;
  font-size: 15px !important;
  border-bottom: none !important;
}

.xb-menu-primary .xb-megamenu .elementor-widget-heading>.elementor-widget-container {
  margin-bottom: 0 !important;
  border-bottom: none !important;
}

.xb-menu-primary .xb-megamenu .elementor-section .elementor-container {
  flex-wrap: wrap;
}

.xb-menu-primary .xb-menu-toggle:hover {
  color: var(--color-primary);
}

.xb-logo-mobile {
  margin-bottom: 40px;
}

.xb-logo-mobile img {
  height: 40px;
}

.xb-header-mobile-search {
  margin-bottom: 20px;
}

.xb-header-mobile-search form {
  position: relative;
}

.xb-header-mobile-search .search-field {
  height: 50px;
  border: none;
  padding: 0 25px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  color: #000;
  border: 2px solid rgba(150, 144, 162, 0.09);
  padding: 12px 15px 13px;
  background-color: transparent;
}

.xb-header-mobile-search .search-field:focus {
  color: #000;
  border-color: var(--color-yellow);
}

.xb-header-mobile-search .search-submit {
  position: absolute;
  top: 5px;
  right: 10px;
  -webkit-border-radius: 0px;
  -khtml-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
  line-height: 36px;
  padding: 0;
  width: 30px;
  height: 40px;
  line-height: 40px;
  background: none;
  color: var(--primary-color);
}

.xb-menu-close {
  background-color: rgba(0, 0, 0, 0.03);
  position: absolute;
  top: 0;
  right: 0;
  -webkit-border-radius: 0px;
  -khtml-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
}

.xb-header-mobile-search .search-submit {
  position: absolute;
  top: 5px;
  right: 10px;
  -webkit-border-radius: 0px;
  -khtml-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
  line-height: 36px;
  padding: 0;
  width: 30px;
  height: 40px;
  line-height: 40px;
  background: none;
  color: var(--color-primary);
}

.xb-nav-mobile {
  font-size: 26px;
  color: var(--color-primary);
}

/*----------------------------------------*/
/*  32. preloader
/*----------------------------------------*/
#preloader {
  background-color: #fff;
  height: 100%;
  width: 100%;
  position: fixed;
  margin-top: 0px;
  top: 0px;
  z-index: 9999;
}

.loader .loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border: 3px solid #ebebec;
  border-radius: 50%;
}

.loader .loader-container:before {
  position: absolute;
  content: "";
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border-top: 3px solid #0b3948;
  border-radius: 50%;
  animation: loaderspin 1.8s infinite linear;
  -webkit-animation: loaderspin 1.8s infinite linear;
}

.preloader-two .loader .loader-container:before {
  border-color: var(--color-yellow);
}

.preloader-three .loader .loader-container:before {
  border-color: #006676;
}

.preloader-four .loader .loader-container:before {
  border-color: #1140c0;
}

.loader .loader-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  text-align: center;
}

.loader .loader-icon img {
  animation: loaderpulse alternate 900ms infinite;
  width: 40px;
}

@keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes loaderpulse {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

.fotoer-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.xb-footer {
  align-items: flex-start;
}

.xb-footer .footer-widget .xb-item--title {
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 18px;
  color: #d8e3f8;
}

.xb-footer .footer-widget .xb-item--contact {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: var(--color-white);
  transform: translateY(16px);
  padding-bottom: 20px;
}

.xb-footer .footer-widget .xb-item--contact span {
  height: 35px;
  width: 35px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: var(--color-primary);
  box-shadow: 0 4px 4px 0 rgba(48, 69, 75, 0.03);
  margin-right: 7px;
}

.xb-footer .footer-widget .xb-item--text {
  color: #d8e3f8;
  font-size: 16px;
}

.xb-footer .footer-widget .xb-item--app_link {
  padding-top: 22px;
}

.xb-footer .footer-widget .xb-item--list a {
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  color: var(--color-white);
  display: inline-block;
}

.xb-footer .footer-widget .xb-item--list:hover a {
  text-decoration: underline;
}

.xb-footer .footer-widget.ec-footer-widget {
  margin-left: -40px;
}

.xb-footer .footer-widget.ec-footer-widget .xb-item--title {
  color: #69f1ff;
}

.xb-footer .footer-newslatter {
  max-width: 446px;
}

@media (max-width: 991px) {
  .xb-footer .footer-newslatter {
    margin-left: -100px;
  }
}

@media (max-width: 767px) {
  .xb-footer .footer-newslatter {
    margin-left: 0;
  }
}

.xb-footer .footer-newslatter .xb-item--title {
  font-size: 40px;
  line-height: 44px;
  color: var(--color-white);
  padding-bottom: 15px;
}

@media (max-width: 767px) {
  .xb-footer .footer-newslatter .xb-item--title {
    font-size: 28px;
    line-height: 36px;
  }
}

.xb-footer .footer-newslatter .xb-item--text {
  font-size: 16px;
  letter-spacing: -0.01em;
  color: #d8e3f8;
}

.xb-footer .footer-newslatter .xb-item--text a {
  color: var(--color-yellow);
}

.xb-footer .footer-newslatter .xb-item--ec-text {
  color: rgba(255, 255, 255, 0.5);
}

.xb-footer .footer-newslatter .xb-item--ec-text a {
  color: var(--color-white);
  text-decoration: underline;
  font-size: 16px;
  letter-spacing: -0.01px;
}

.xb-footer .footer-newslatter .xb-item--new_text {
  font-size: 16px;
  color: var(--color-white);
}

.xb-footer .footer-newslatter .xb-item--text_two {
  color: #dcd7dd;
  max-width: 291px;
  font-size: 16px;
}

.xb-footer .footer-newslatter .xb-item--subscription {
  gap: 10px;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .xb-footer .footer-newslatter .xb-item--subscription {
    flex-wrap: wrap;
  }
}

.xb-footer .footer-newslatter .xb-item--subscription input {
  height: auto;
  border-radius: 5px;
}

.xb-footer .footer-newslatter .xb-item--subscription .xb-email img {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding-left: 20px;
}

.xb-footer .footer-newslatter .xb-item--subscription .xb-email input {
  padding: 10px 20px 10px 45px;
  width: 290px;
}

.xb-footer .footer-newslatter .xb-item--subscription .xb-email--two input {
  width: 339px;
  padding: 14px 65px 14px 45px;
}

.xb-footer .footer-newslatter .xb-item--subscription .xb-field-btn button {
  text-align: center;
  max-width: 146px;
  font-weight: 600;
  font-size: 18px;
  color: var(--color-heading);
  background: var(--color-yellow);
  border: none;
  padding: 10px 30px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.xb-footer .footer-newslatter .xb-item--ec_subscription .xb-email input {
  padding: 12px 20px 12px 45px;
  border-radius: 60px;
}

.xb-footer .footer-newslatter .xb-item--ec_subscription .xb-field-btn button {
  background: #1140c0;
  color: var(--color-white);
  padding: 12px 30px;
  border-radius: 60px;
}

.xb-footer .footer-newslatter.change-padding {
  padding-top: 8px;
}

.xb-footer.ec-footer {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-bottom {
  border-top: 1px solid #2e3749;
  padding-top: 18px;
  padding-bottom: 33px;
}

@media (max-width: 1199px) {
  .footer-bottom {
    padding: 22px 0px;
    padding-top: 7px;
  }
}

@media (max-width: 991px) {
  .footer-bottom {
    padding: 22px 0px;
    padding-top: 7px;
  }
}

@media (max-width: 767px) {
  .footer-bottom {
    justify-content: center;
    text-align: center;
  }
}

.footer-bottom .copyright p {
  color: var(--color-white);
}

.footer-bottom .copyright p a {
  color: var(--color-white);
}

.footer-bottom .social-link p {
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
  text-transform: uppercase;
  color: var(--color-white);
  padding-right: 15px;
}

.footer-bottom .social-link a {
  color: rgba(255, 255, 255, 0.5);
}

.footer-bottom .social-link a:not(:last-child) {
  margin-right: 15px;
}

.footer-bottom .social-link a:hover {
  color: var(--color-yellow);
}

.footer-bottom .social-link a:hover svg path {
  fill: var(--color-yellow);
}

.footer-bottom .social-link a svg {
  height: 16px;
}

.footer-bottom .social-link a svg path {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.footer-bottom .link-bar a {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background: var(--color-primary);
  border: 1px solid #234d5a;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.footer-bottom .link-bar a:not(:last-child) {
  margin-right: 10px;
}

.footer-bottom .link-bar a i {
  color: var(--color-white);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.footer-bottom .link-bar a:hover {
  background: #ffcb05;
}

.footer-bottom .link-bar a:hover i {
  color: var(--color-primary);
}

.footer-bottom .link-bar a:hover img {
  filter: brightness(0);
}

.footer-bottom--two {
  border: none;
  background: var(--color-primary);
  padding-top: 15px;
  padding-bottom: 30px;
}

.footer-bottom--three {
  background: none;
}

.footer-bottom--three .copyright p {
  color: #8384a1;
}

.footer-bottom--three .copyright p a {
  color: #8384a1;
}

.footer-bottom--three .social-link p {
  color: #8384a1;
}

.footer-bottom--three .social-link a i {
  color: var(--color-white);
}

.footer-bottom--three .social-link a:hover i {
  color: #69f1ff;
}

.xb-support_box {
  background: #67a859;
  text-align: center;
  padding: 50px 45px;
}

.xb-support_box .xb-item--sup_shape {
  position: absolute;
  bottom: 0;
  right: 0;
}

.xb-support_box .xb-item--icon img {
  height: 88px;
  width: auto;
}

.xb-support_box .xb-item--title {
  font-size: 30px;
  line-height: 40px;
  color: var(--color-white);
  padding: 28px 0 10px 0;
}

.xb-support_box .xb-item--content {
  color: var(--color-white);
}

.xb-support_box .xb-item--sup-button .sup-btn {
  background-color: var(--color-white);
  padding: 22px 38px;
}

.xb-support_box .xb-item--sup-button .sup-btn:hover {
  background-color: var(--color-yellow);
  color: var(--color-primary);
}

.xb-support_box .xb-item--sup-button .sup-btn:hover svg path {
  fill: var(--color-primary);
}

.xb-support_box.clr-two {
  background: #b73b34;
}

.xb-support_box.clr-three {
  background: #8139e7;
}

.xb-frm-btn {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 5px 5px 0;
  width: 60px;
  height: 100%;
  background: #FFCB05;
}

.footer-widget-wrap .footer-col:nth-child(2) .footer-widget {
  margin-left: -70px;
}

@media (max-width: 1199px) {
  .footer-widget-wrap .footer-col:nth-child(2) .footer-widget {
    margin-left: 0;
  }
}

.footer-widget-wrap .footer-col:nth-child(3) .footer-widget {
  margin-left: -25px;
}

@media (max-width: 1199px) {
  .footer-widget-wrap .footer-col:nth-child(3) .footer-widget {
    margin-left: 0;
  }
}

.footer-style-two .xb-footer,
.footer-style-three .xb-footer {
  margin-left: -15px;
  margin-right: -15px;
}

@media (max-width: 991px) {

  .footer-style-two .footer-newslatter,
  .footer-style-three .footer-newslatter {
    margin-left: 0;
  }
}

.footer-style-two .footer-widget,
.footer-style-three .footer-widget {
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 991px) {

  .footer-style-two .footer-widget,
  .footer-style-three .footer-widget {
    width: 50%;
  }
}

@media (max-width: 767px) {

  .footer-style-two .footer-widget,
  .footer-style-three .footer-widget {
    width: 100%;
  }
}

.footer-style-two .xb-footer .footer-widget .xb-item--title {
}

@media (max-width: 767px) {
  .footer-bottom-inner {
    justify-content: center;
  }
}

@media (max-width: 991px) {
  .footer-style-three .footer-widget {
    margin-left: 0 !important;
  }

  .footer-style-three .footer-widget:nth-child(1) {
    width: 70%;
  }
}

@media (max-width: 991px) and (max-width: 767px) {
  .footer-style-three .footer-widget:nth-child(1) {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .footer-style-three .footer-widget:nth-child(2) {
    width: 30%;
  }
}

@media (max-width: 991px) and (max-width: 767px) {
  .footer-style-three .footer-widget:nth-child(2) {
    width: 100%;
  }
}

.footer-widget {
  z-index: 1;
  position: relative;
}

.sc-footer-social li:not(:last-child) {
  margin-right: 25px;
}

.sc-footer-social li a {
  color: var(--color-white);
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sc-footer-social li a:hover {
  -webkit-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  transform: translateY(-3px);
}

.sc-footer-social li a svg {
  height: 23px;
}

.sc-footer-social li a svg path {
  fill: var(--color-white);
}

.sc-footer-widget .xb-item--title {
  font-size: 20px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 18px;
  letter-spacing: -0.01em;
  font-family: var(--font-body);
}

.sc-footer-widget .xb-item--link li:not(:last-child) {
  margin-bottom: 11px;
}

.sc-footer-widget .xb-item--link li a {
  color: #92ACB0;
}

.sc-footer-widget .xb-item--link li a:hover {
  color: #fff;
}

.sc-footer-widget p {
  color: #92ACB0;
  line-height: 36px;
}

.footer-style-four {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.footer-style-four .footer-col:nth-child(3) .sc-footer-widget {
  padding-left: 44px;
}

@media (max-width: 1199px) {
  .footer-style-four .footer-col:nth-child(3) .sc-footer-widget {
    padding-left: 0;
  }
}

.footer-style-four .footer-col:nth-child(4) .sc-footer-widget {
  padding-left: 20px;
}

@media (max-width: 1199px) {
  .footer-style-four .footer-col:nth-child(4) .sc-footer-widget {
    padding-left: 0;
  }
}

.footer-style-four .xb-footer-wrap {
  border-bottom: 2px solid rgba(255, 255, 255, 0.11);
}

.sc-footer-text {
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
}

.sc-copyright-text {
  padding: 29px 0;
  color: #92ACB0;
}
.breadcrumb-item.active{
  color: #FFFFFF;
}